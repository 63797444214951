import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";

import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { withStyles } from "@material-ui/core/styles";

import {
  mainListItems,
  secondaryListItems,
  externalListItems
} from "./DashboardItems";

// import { userActions } from '../_actions';

class PageWithAppDrawer extends React.Component {
  constructor(props) {
    super(props);
    console.log("PageWithAppDrawer props", props);

    this.state = {
      isElectron: false,
      open: props.showMenu || false,
      currentPage: "Dashboard"
    };
  }

  componentDidMount() {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log("userAgent", userAgent);
    if (userAgent.indexOf(" electron/") > -1) {
      this.setState({
        isElectron: true
      });
    }
  }

  drawerToggle = () => {
    this.setState((prevState, props) => ({
      open: !prevState.open
    }));
  };

  menuClickHandler = target => {
    console.log(target);
    this.setState({
      currentPage: target,
      open: false
    });
  };

  externalClickHandler = target => {
    console.log(target);
    let executablePath = null;
    if (target === "Excel") {
      executablePath =
        "/Applications/Microsoft Excel.app/Contents/MacOS/Microsoft Excel";
    }
    if (target === "Word") {
      executablePath =
        "/Applications/Microsoft Word.app/Contents/MacOS/Microsoft Word";
    }
    if (target === "PowerPoint") {
      executablePath =
        "/Applications/Microsoft PowerPoint.app/Contents/MacOS/Microsoft PowerPoint";
    }

    if (executablePath !== null) {
      // const { shell } = require("electron");
      // const rc = shell.openItem("executablePath");

      let child = require("child_process").execFile;
      child(executablePath, function(err, data) {
        if (err) {
          console.error(err);
          return;
        }
        console.log(data.toString());
      });
    }
  };

  render() {
    const { classes, theme } = this.props;
    const { user, isAdmin } = this.props;
    const { pageTitle } = this.props;
    const { open, isElectron } = this.state;
    const mainMenu = mainListItems(this.menuClickHandler, isAdmin);
    const secondaryMenu = secondaryListItems(this.menuClickHandler, isAdmin);
    const externalMenu = externalListItems(this.externalClickHandler, isAdmin);

    // console.log("isAdmin", isAdmin);

    if (!user || !user.name) {
      return (
        <div>
          <div className="alert alert-danger" role="alert">
            Error
          </div>
          <p>
            <RouterLink to="/login">Logout</RouterLink>
          </p>
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.drawerToggle}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {pageTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            Version2
            <IconButton onClick={this.drawerToggle}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>{mainMenu}</List>
          <Divider />
          <List>{secondaryMenu}</List>
          {isElectron && (
            <div>
              <Divider />
              <List>{externalMenu}</List>
            </div>
          )}
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <div className={classes.drawerHeader} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
});

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  const isAdmin = user && user.dms_user && user.dms_user.is_admin === true;
  return {
    user,
    isAdmin
  };
}

const connectedPageWithAppDrawer = connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(PageWithAppDrawer)
);
export { connectedPageWithAppDrawer as PageWithAppDrawer };
