import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

import { formatNumberToStringMoney } from "../../_helpers";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
// import Fab from "@material-ui/core/Fab";
// import AddIcon from "@material-ui/icons/Add";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";

export function ModalFrontGrossDetails(props) {
  const { onClose, open, record } = props;
  return (
    <Dialog onClose={onClose} open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Front Gross</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th">Vehicle Price:</TableCell>
              <TableCell>
                {formatNumberToStringMoney(record.deal.price)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">+ Doc Fee:</TableCell>
              <TableCell>
                {formatNumberToStringMoney(record.deal.fees_doc)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">- Vehicle Cost</TableCell>
              <TableCell>
                {record
                  ? formatNumberToStringMoney(record.inventory.buy_net)
                  : 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">- Vehicle Expenses</TableCell>
              <TableCell>
                {record
                  ? formatNumberToStringMoney(record.inventory.expenses_total)
                  : 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
