import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { getUsStates } from "../../_helpers";

import Container from "@material-ui/core/Container";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function DealBuyerInfo(props) {
  const classes = useStyles();

  // state
  //   const [salespersonsOpen, setSalespersonsOpen] = React.useState(false);

  const {
    onChangeRecordProperty,
    // onChangeDealProperty,
    // onChangeInventoryProperty,
    dealerList,
    leadSourcesList
  } = props;

  const { record } = props || {};
  //   const { date_inspection, date_sale, date_title } = record;

  return (
    <Container className={classes.container}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Buyer Type</TableCell>
            <TableCell>
              <Select
                name="dealer_to_dealer"
                value={record.dealer_to_dealer}
                onChange={onChangeRecordProperty}
              >
                <MenuItem value={1}>Dealer</MenuItem>
                <MenuItem value={0}>Consumer</MenuItem>
              </Select>
            </TableCell>
          </TableRow>
          {record.dealer_to_dealer == 1 && (
            <TableRow>
              <TableCell>Buying Dealer</TableCell>
              <TableCell>
                <Select
                  name="dealer_buyer_id"
                  value={record.dealer_buyer_id || ""}
                  onChange={onChangeRecordProperty}
                >
                  <MenuItem value="">--Buying Dealer--</MenuItem>
                  {dealerList.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
            </TableRow>
          )}
          {record.dealer_to_dealer !== 1 && (
            <>
              <TableRow>
                <TableCell>Lead Source</TableCell>
                <TableCell>
                  <Select
                    name="buyer_lead_source"
                    value={record.buyer_lead_source || ""}
                    onChange={onChangeRecordProperty}
                  >
                    {leadSourcesList.map((item, index) => {
                      return (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Buyer Name 1</TableCell>
                <TableCell>
                  <Input
                    value={record.buyer_name}
                    name="buyer_name"
                    style={{ width: "100%" }}
                    onChange={onChangeRecordProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Buyer Name 2</TableCell>
                <TableCell>
                  <Input
                    value={record.buyer_name2}
                    name="buyer_name2"
                    style={{ width: "100%" }}
                    onChange={onChangeRecordProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Buyer Email Address</TableCell>
                <TableCell>
                  <Input
                    value={record.buyer_email}
                    name="buyer_email"
                    style={{ width: "100%" }}
                    onChange={onChangeRecordProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Buyer Address</TableCell>
                <TableCell>
                  <Input
                    value={record.buyer_address}
                    name="buyer_address"
                    style={{ width: "100%" }}
                    onChange={onChangeRecordProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Buyer City</TableCell>
                <TableCell>
                  <Input
                    value={record.buyer_city}
                    name="buyer_city"
                    style={{ width: "100%" }}
                    onChange={onChangeRecordProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Buyer Zip</TableCell>
                <TableCell>
                  <Input
                    value={record.buyer_zip}
                    name="buyer_zip"
                    onChange={onChangeRecordProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Buyer Phone 1</TableCell>
                <TableCell>
                  <Input
                    value={record.buyer_phone1}
                    name="buyer_phone1"
                    onChange={onChangeRecordProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Buyer Phone 2</TableCell>
                <TableCell>
                  <Input
                    value={record.buyer_phone2}
                    name="buyer_phone2"
                    onChange={onChangeRecordProperty}
                  />
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  // paper: {
  //   padding: theme.spacing(2),
  //   display: "flex",
  //   overflow: "auto",
  //   flexDirection: "column",
  //   width: "100%"
  // },
  fixedHeight: {
    height: 240
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
