import React, { PureComponent } from "react";
// import ReactModal from "react-modal";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const StyledTH = withStyles(theme => ({
  root: {
    color: "blue",
    fontWeight: "bold"
  }
}))(TableCell);

export default class InventoryMasterDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  render() {
    // const { expanded } = this.state;
    const { item: i } = this.props;
    const hasTitle = i.has_title == 1 ? i.title_received_date : "No Title";

    return (
      <Table size="small">
        <TableBody>
          <TableRow>
            <StyledTH component="th" scope="row">
              VIN
            </StyledTH>
            <TableCell align="left">{i.vin}</TableCell>
          </TableRow>
          <TableRow>
            <StyledTH component="th" scope="row">
              Date Purchased
            </StyledTH>
            <TableCell align="left">{i.date_purchased}</TableCell>
          </TableRow>
          <TableRow>
            <StyledTH component="th" scope="row">
              Has Title
            </StyledTH>
            <TableCell align="left">{hasTitle}</TableCell>
          </TableRow>
          <TableRow>
            <StyledTH component="th" scope="row">
              Title Notes
            </StyledTH>
            <TableCell align="left">{i.title_notes}</TableCell>
          </TableRow>
          <TableRow>
            <StyledTH component="th" scope="row">
              Vehicle Status
            </StyledTH>
            <TableCell align="left">{i.vehicle_status}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  toggleMoreInfo = () => {
    this.setState(prevState => {
      return { expanded: !prevState.expanded };
    });
  };
}

const styles = theme => ({
  root: {
    width: "100%"
  },
  head: {
    fontWeight: "bold"
  }
});

function mapStateToProps(state) {
  const { dms } = state;
  return {
    dms
  };
}

const connectedInventoryMasterDetails = connect(mapStateToProps)(
  withStyles(styles)(InventoryMasterDetails)
);
export { connectedInventoryMasterDetails as InventoryMasterDetails };
