import { configConstants } from "../_constants";

export function configs(state = {}, action) {
  switch (action.type) {
    case configConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case configConstants.GETALL_SUCCESS:
      return {
        items: action.configs
      };
    case configConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case configConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map(config =>
          config.id === action.config.id
            ? { ...config, updating: true }
            : config
        )
      };
    case configConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map(config =>
          config.id === action.config.id
            ? { ...config, updating: false, value: action.value }
            : config
        )
      };
    case configConstants.UPDATE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    default:
      return state;
  }
}
