import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import { dmsActions, userActions } from "../_actions";

import { PageWithAppDrawer } from "../_components/main/PageWithAppDrawer";
import LienholdersTable from "./LienholdersTable";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

class LienholdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: this.props.dms.lienholders.page || 1,
      per_page: this.props.dms.lienholders.per_page || 10,
      total: this.props.dms.lienholders.total || 0,
      filter: "",
      modalOpen: false,
      modalTitle: null,
      modalContent: []
    };
  }
  componentDidMount() {
    const { lienholders } = this.props.dms;
    if (!lienholders.loading && lienholders.items.length == 0) {
      this.props.dispatch(dmsActions.getLienholders(null, null, 100, 1));
      this.getNewData();
    }
  }

  render() {
    const { classes, dms } = this.props;
    const { loading, items, page, per_page, total } = dms.lienholders;

    if (loading) {
      return (
        <Container maxWidth="lg" className={classes.container}>
          <div>Loading...</div>
          <p>
            <Link to="/login">Logout</Link>
          </p>
        </Container>
      );
    }

    // console.log("lienholders", items);

    return (
      <div>
        <PageWithAppDrawer pageTitle={"Lienholders"}>
          <LienholdersTable
            rows={items}
            page={Number(page) - 1}
            per_page={Number(per_page)}
            total={Number(total)}
            searchQuery={this.state.filter}
            onChangeData={this.handleChangeData}
            onChangeFilter={this.handleChangeFilter}
            onUpdateObject={this.handleUpdateObject}
          />
        </PageWithAppDrawer>
      </div>
    );
  }

  handleChangeFilter = text => {
    this.setState({ filter: text }, () => {
      this.getNewData();
    });
  };

  handleChangeData = (page, per_page) => {
    page++;
    console.log("handleChangeData", { page, per_page });
    this.getNewData(page, per_page);
  };

  getNewData = (page = null, per_page = null) => {
    if (!per_page) per_page = this.state.per_page;
    if (!page) page = this.state.page;
    this.setState({
      page: page,
      per_page: per_page
    });
    let filters = [];
    if (this.state.filter) {
      filters.push({
        field: "name",
        operator: "like",
        value: this.state.filter
      });
    }
    // console.log({ page, per_page });
    this.props.dispatch(
      dmsActions.getLienholders(
        filters, // filters
        null, // relations
        per_page,
        page
      )
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage + 1
      },
      () => this.getNewData()
    );
  };

  handleChangeRowsPerPage = event => {
    const value = parseInt(event.target.value, 10);
    this.setState(
      {
        per_page: value,
        page: 1
      },
      () => this.getNewData()
    );
  };

  handleUpdateObject = item => {
    console.log(item);
    this.props.dispatch(dmsActions.updateLienholder(item, item));
  };
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
});

function mapStateToProps(state) {
  const { dms } = state;
  return {
    dms
  };
}

const connectedLienholdersPage = connect(mapStateToProps)(
  withStyles(styles)(LienholdersPage)
);
export { connectedLienholdersPage as LienholdersPage };
