import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
// import Link from "@material-ui/core/Link";
// import { Link as RouterLink } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

export default function DealDocuments(props) {
  const classes = useStyles();

  // state
  const [selectedDocumentType, setSelectedDocumentType] = React.useState(-1);

  const { record, documentTypes } = props;

  if (!record) {
    return <Container className={classes.container}>Loading...</Container>;
  }

  const { documents } = record;

  return (
    <Container className={classes.container}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Add Document</TableCell>
            <TableCell>
              <Select
                name="sale_type"
                value={selectedDocumentType}
                onChange={e => setSelectedDocumentType(e.target.value)}
              >
                <MenuItem value={-1}>--Document Type--</MenuItem>
                {documentTypes.map((dt, i) => {
                  return (
                    <MenuItem key={dt.id} value={dt.id}>
                      {dt.name}
                    </MenuItem>
                  );
                })}
              </Select>{" "}
              {selectedDocumentType > 0 && (
                <Button variant="contained" color="primary">
                  Add Document
                </Button>
              )}
            </TableCell>
          </TableRow>
          {documents.length > 0 && (
            <TableRow>
              <TableCell>
                All Documents{" "}
                <IconButton color="secondary" size="small" onClick={() => {}}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  target="_blank"
                  href={`https://api2.autonerdusa.com/api/records/${record.id}/documents/all`}
                >
                  Download All Documents
                </Button>
              </TableCell>
            </TableRow>
          )}
          {documents.length > 0 &&
            documents.map((d, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    {d.type}{" "}
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() => {}}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="default"
                      target="_blank"
                      href={`https://api2.autonerdusa.com/api/records/${record.id}/documents/${d.id}`}
                    >
                      {d.type} -> Download
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  // paper: {
  //   padding: theme.spacing(2),
  //   display: "flex",
  //   overflow: "auto",
  //   flexDirection: "column",
  //   width: "100%"
  // },
  fixedHeight: {
    height: 240
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
