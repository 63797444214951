import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  formatNumberToStringMoney,
  recordFeesTotal,
  recordAmountFinanced,
  // recordCashDealTotal,
  recordTotalTax,
  // recordTaxableAmount,
  // recordAccessoriesTotal,
  recordFrontGross,
  recordMonthlyPayment,
  recordTradesAllowanceTotal,
  recordTradesPayoffTotal
} from "../../_helpers";

import { ModalFrontGrossDetails } from "./DealDetailsModals";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  }
}));

export default function DealRecapTable(props) {
  const classes = useStyles();

  // state
  const [frontGrossOpen, setFrontGrossOpen] = React.useState(false);

  const { record } = props || {};
  const { deal } = record || {};
  const {
    price,
    down_payment_cash,
    is_finance,
    finance_apr,
    finance_buy_rate,
    finance_term,
    fees_doc
  } = deal;

  const total_tax = recordTotalTax(record);
  const fees_total = recordFeesTotal(record);
  const amountFinanced = recordAmountFinanced(record);
  const frontGross = recordFrontGross(record);
  const monthlyPayment = recordMonthlyPayment(record);
  const tradesAllowanceTotal = recordTradesAllowanceTotal(record);
  const tradesPayoffTotal = recordTradesPayoffTotal(record);

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ verticalAlign: "top" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>Deal Structure</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Price:</TableCell>
                    <TableCell>{formatNumberToStringMoney(price)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Cash Down:</TableCell>
                    <TableCell>
                      {formatNumberToStringMoney(down_payment_cash)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Total Tax:</TableCell>
                    <TableCell>
                      {formatNumberToStringMoney(total_tax)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Total Fees:</TableCell>
                    <TableCell>
                      {formatNumberToStringMoney(fees_total)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Trade Payoff:</TableCell>
                    <TableCell>
                      {formatNumberToStringMoney(tradesPayoffTotal)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th">Trade Value:</TableCell>
                    <TableCell>
                      {formatNumberToStringMoney(tradesAllowanceTotal)}
                    </TableCell>
                  </TableRow>
                  {is_finance && (
                    <>
                      <TableRow>
                        <TableCell component="th">Term:</TableCell>
                        <TableCell>{finance_term} Months</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">APR:</TableCell>
                        <TableCell>{finance_apr}%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th">Buy Rate:</TableCell>
                        <TableCell>{finance_buy_rate}%</TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableCell>
            <TableCell style={{ verticalAlign: "top" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>Deal Information</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
            <TableCell style={{ verticalAlign: "top" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>Deal Summary</TableCell>
                  </TableRow>
                  {is_finance && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <b>
                                  {formatNumberToStringMoney(monthlyPayment)}
                                </b>{" "}
                                /month
                              </TableCell>
                              <TableCell>
                                <b>{finance_apr}%</b> APR
                              </TableCell>
                              <TableCell>
                                <b>{finance_term}</b> term
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Cash Down</TableCell>
                    <TableCell>
                      {formatNumberToStringMoney(down_payment_cash)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Amount Financed</TableCell>
                    <TableCell>
                      {formatNumberToStringMoney(amountFinanced)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Finance Charge</TableCell>
                    <TableCell>
                      <i>Finance Charge goes here</i>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Deal Costs</TableCell>
                    <TableCell>
                      <i>Deal Costs go here</i>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Commissions</TableCell>
                    <TableCell>
                      <i>Commissions go here</i>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <b>Gross</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Back</TableCell>
                    <TableCell>
                      <i>Back GROSS goes here</i>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Front</TableCell>
                    <TableCell>
                      {formatNumberToStringMoney(frontGross)}
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          setFrontGrossOpen(!frontGrossOpen);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Total Gross</b>
                    </TableCell>
                    <TableCell>
                      <i>Total GROSS goes here</i>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ModalFrontGrossDetails
        open={frontGrossOpen}
        onClose={() => {
          setFrontGrossOpen(!frontGrossOpen);
        }}
        record={record}
      />
    </>
  );
}
