import { dashboardConstants } from "../_constants";

const initialState = {
  loading: false,
  leadGraphData: {
    total: 100,
    open: 50,
    sold: 30,
    closed: 20
  }
};

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case dashboardConstants.GETALL_SUCCESS:
      return {
        items: action.dashboard
      };
    case dashboardConstants.GETALL_FAILURE:
      return {
        error: action.dashboard
      };
    default:
      return state;
  }
}
