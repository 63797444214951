import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { users } from "./users.reducer";
import { configs } from "./configs.reducer";
import { sites } from "./site.reducer";
import { dms } from "./dms.reducer";
import { dashboard } from "./dashboard.reducer";

import { alert } from "./alert.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  configs,
  sites,
  alert,
  dms,
  dashboard
});

export default rootReducer;
