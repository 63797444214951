import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { dmsActions, userActions } from "../_actions";
import "./Dms.css";
// import VehicleCard from "../_components/VehicleCard";
import { Deal } from "../_components/deal/Deal";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

class DealPage extends React.Component {
  componentDidMount() {
    const { recordId } = this.props.match.params;
    const query = this.props.location.search;
    if (query === "?key=f4d4mg98") {
      // this.props.dispatch(userActions.setEnvironment('Development'));
      localStorage.setItem("environment", JSON.stringify("Development"));
      this.props.dispatch(
        userActions.login(
          "primordius@gmail.com",
          "m0rtalp1",
          "/deal/" + recordId
        )
      );
    } else {
      this.props.dispatch(dmsActions.getDeal(recordId));
      this.props.dispatch(dmsActions.getGapCompanies());
      this.props.dispatch(dmsActions.getServiceCompanies());
      this.props.dispatch(dmsActions.getDealers([], [], 1000));
      this.props.dispatch(dmsActions.getDocumentTypes([], [], 1000));
      this.props.dispatch(dmsActions.getSalespersons([], [], 1000));
      this.props.dispatch(dmsActions.getLeadSources([], [], 1000));
      this.props.dispatch(dmsActions.getLienholders([], [], 1000));
    }

    // this.props.location.query.__firebase_request_key
    // console.log("router query", this.props.location.search);
  }

  render() {
    const { dms } = this.props;
    const { loading, loadingLineItems, currentRecord: record } = dms;

    if (this.props.location.search === "?key=f4d4mg98") {
      return <div>autologin</div>;
    }

    const inventory = record && record.inventory ? record.inventory : {};
    const deal = record && record.deal ? record.deal : {};
    const line_items = record && record.line_items ? record.line_items : [];

    return (
      <div>
        <Grid container>
          {loading && <Grid item>Loading</Grid>}
          {!loading && (
            <Grid item>
              <Deal
                item={deal}
                record={record}
                line_items={loadingLineItems ? [] : line_items}
                loading={loading}
                loadingLineItems={loadingLineItems}
                onSaveRecord={this.saveRecord}
                onSaveDeal={this.saveDeal}
                onSaveField={this.updateSingleValue}
                onLineItemDelete={this.lineItemDelete}
                onLineItemAdd={this.lineItemAdd}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }

  saveDeal = (deal, line_items) => {
    console.log("save deal", deal);
    console.log("save line_items", line_items);
    const { currentRecord: record } = this.props.dms;
    this.props.dispatch(dmsActions.dealUpdate(record.id, deal, line_items));
  };

  saveRecord = () => {
    const { currentRecord: record } = this.props.dms;
    console.log("save record", record);
    this.props.dispatch(dmsActions.saveRecord(record));
  };

  lineItemDelete = line_item => {
    this.props.dispatch(dmsActions.lineItemDelete(line_item));
  };
  lineItemAdd = () => {
    const { dms } = this.props;
    const { currentRecord: record } = dms;
    this.props.dispatch(dmsActions.lineItemAdd(record));
  };
  updateSingleValue = (field, value) => {
    const { currentRecord: record } = this.props.dms;
    let update = {};
    update[field] = value;
    this.props.dispatch(dmsActions.dealUpdate(record.id, update));
  };
}

function mapStateToProps(state) {
  const { dms, authentication } = state;
  const { user } = authentication;
  return {
    user,
    dms
  };
}

const connectedDealPage = connect(mapStateToProps)(DealPage);
export { connectedDealPage as DealPage };
