import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { ModalReserve } from "./ModalReserve";

import {
  formatNumberToStringMoney,
  recordFeesTotal,
  recordAmountFinanced,
  recordCashDealTotal,
  recordTotalTax,
  recordTaxableAmount,
  recordAccessoriesTotal,
  // recordFrontGross,
  recordMonthlyPayment,
  recordTradesAllowanceTotal,
  recordFinanceReserveValue,
  recordBuyRateInterest,
  recordParticipationRateInterest
} from "../../_helpers";

import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  }
}));

export default function DealDetails(props) {
  const classes = useStyles();

  const {
    onChangeRecordProperty,
    onChangeDealProperty,
    onChangeAccessoryProperty,
    onAddAccessory,
    onDeleteAccessory
  } = props;

  const [feesOpen, setFeesOpen] = React.useState(false);
  const [serviceContractOpen, setServiceContractOpen] = React.useState(false);
  const [gapOpen, setGapOpen] = React.useState(false);
  const [accessoriesOpen, setAccessoriesOpen] = React.useState(false);
  const [taxOpen, setTaxOpen] = React.useState(false);
  const [financeInterestOpen, setFinanceInterestOpen] = React.useState(false);
  const [tradesOpen, setTradesOpen] = React.useState(false);
  const [reserveOpen, setReserveOpen] = React.useState(false);

  const { gapCompanies, serviceCompanies, lienholders } = props;
  const { record } = props || {};
  const { deal } = record || {};
  const line_items = record && record.line_items ? record.line_items : [];

  const {
    is_finance,
    lienholder_id,
    price,
    down_payment_cash,
    finance_term,
    finance_apr,
    finance_addon_rate,
    finance_contract_apr,
    finance_buy_rate,
    finance_participation_rate,
    finance_interest_spread,
    finance_flat_reserve,
    fees_license,
    fees_title,
    fees_filing,
    fees_other,
    fees_doc,
    fees_lender_processing,
    service_contract_price,
    service_contract_term,
    service_contract_mileage,
    service_contract_cost,
    service_contract_company_id,
    gap_price,
    gap_term,
    gap_cost,
    gap_company_id,
    tax_rate
    // trade1,
    // trade1_id,
    // trade1_allowance,
    // trade1_acv,
    // trade1_payoff,

    // trade2,
    // trade2_id,
    // trade2_allowance,
    // trade2_acv,
    // trade2_payoff
  } = deal || 0;

  const fees_total = recordFeesTotal(record);
  const accessories_total = recordAccessoriesTotal(record);
  const taxableAmount = recordTaxableAmount(record);
  const total_tax = recordTotalTax(record);
  // const total_trade_value = 0;
  const amountFinanced = recordAmountFinanced(record);
  const cashDealTotal = recordCashDealTotal(record);
  const tradesAllowanceTotal = recordTradesAllowanceTotal(record);

  // const frontGross = recordFrontGross(record);

  const months = Number(finance_term);
  const effectiveApr = Number(finance_contract_apr) + Number(finance_buy_rate);
  const monthlyApr = Number(effectiveApr) / 100 / 12;
  const numerator = monthlyApr * Math.pow(1 + monthlyApr, months);
  const denominator = Math.pow(1 + monthlyApr, months) - 1;
  // const monthlyPayment = ((Number(price) * numerator) / denominator)
  //   .toFixed(2)
  //   .toString();
  const monthlyPayment = recordMonthlyPayment(record);
  const reserveValue = recordFinanceReserveValue(record);

  const buyRateInterest = recordBuyRateInterest(record);
  const participationRateInterest = recordParticipationRateInterest(record);
  const interestSpread = participationRateInterest - buyRateInterest;
  const earnedOnReserve =
    record.deal.finance_reserve_type == "percent of finance"
      ? interestSpread
      : (interestSpread *
          (100 - record.deal.finance_reserve_bank_hold_percent)) /
        100;

  console.log("monthlyPayment", monthlyPayment);

  return (
    <>
      <Checkbox
        checked={is_finance}
        onChange={onChangeDealProperty}
        name="is_finance"
        value={true}
        color="primary"
      />{" "}
      Finance
      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">
              <b>Price:</b>
            </TableCell>
            <TableCell component="th">
              <Input
                value={price}
                name="price"
                onChange={onChangeDealProperty}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Fees:</TableCell>
            <TableCell>
              {formatNumberToStringMoney(fees_total)}
              <IconButton
                color="primary"
                size="small"
                onClick={() => setFeesOpen(!feesOpen)}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Service Contract:</TableCell>
            <TableCell>
              {formatNumberToStringMoney(service_contract_price)}
              <IconButton
                color="primary"
                size="small"
                onClick={() => setServiceContractOpen(!serviceContractOpen)}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Gap Contract:</TableCell>
            <TableCell>
              {formatNumberToStringMoney(gap_price)}
              <IconButton
                color="primary"
                size="small"
                onClick={() => setGapOpen(!gapOpen)}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Accessories:</TableCell>
            <TableCell>
              {formatNumberToStringMoney(accessories_total)}
              <IconButton
                color="primary"
                size="small"
                onClick={() => setAccessoriesOpen(!accessoriesOpen)}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th">Trade In:</TableCell>
            <TableCell>
              {formatNumberToStringMoney(tradesAllowanceTotal)}
              <IconButton
                color="primary"
                size="small"
                onClick={() => setTradesOpen(!tradesOpen)}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th">Total Tax:</TableCell>
            <TableCell>
              {formatNumberToStringMoney(total_tax)}
              <IconButton
                color="primary"
                size="small"
                onClick={() => setTaxOpen(!taxOpen)}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">Down Payment:</TableCell>
            <TableCell>
              <Input
                value={down_payment_cash}
                name="down_payment_cash"
                onChange={onChangeDealProperty}
              />
            </TableCell>
          </TableRow>
          {!is_finance && (
            <TableRow>
              <TableCell component="th">
                <b>Cash Deal Total:</b>
              </TableCell>
              <TableCell>{formatNumberToStringMoney(cashDealTotal)}</TableCell>
            </TableRow>
          )}
        </TableBody>
        {is_finance && (
          <TableBody>
            <TableRow>
              <TableCell component="th">
                <b>Amount Financed:</b>
              </TableCell>
              <TableCell>{formatNumberToStringMoney(amountFinanced)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Lienholder:</TableCell>
              <TableCell>
                <Select
                  name="lienholder_id"
                  value={lienholder_id == null ? "" : lienholder_id}
                  onChange={onChangeDealProperty}
                  displayEmpty
                >
                  <MenuItem value="">--Choose Lienholder--</MenuItem>
                  {lienholders.map(lienholder => {
                    return (
                      <MenuItem key={lienholder.id} value={lienholder.id}>
                        {lienholder.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">
                Reserve:
                <br />
                Interest:
              </TableCell>
              <TableCell>
                {formatNumberToStringMoney(earnedOnReserve)}
                <br />
                {finance_apr}%
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => setReserveOpen(!reserveOpen)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Term (months):</TableCell>
              <TableCell>
                <Input
                  value={finance_term}
                  name="finance_term"
                  onChange={onChangeDealProperty}
                />
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell component="th">Interest Rate:</TableCell>
              <TableCell>
                {finance_apr}%
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => setFinanceInterestOpen(!financeInterestOpen)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell component="th">
                <b>Monthly Payment:</b>
              </TableCell>
              <TableCell>{formatNumberToStringMoney(monthlyPayment)}</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <ModalReserve
        open={reserveOpen}
        onClose={() => setReserveOpen(!reserveOpen)}
        record={record}
        onChangeDealProperty={onChangeDealProperty}
      />
      <Dialog
        open={tradesOpen}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Trade Ins</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" colSpan={3}>
                  <Typography variant="h6" align="center">
                    Trade 1
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" colSpan={3}>
                  {record.trade1 && (
                    <>
                      {`${record.trade1.year} ${record.trade1.make} ${record.trade1.model} ${record.trade1.color}`}
                      {/* <IconButton
                        color="primary"
                        size="small"
                        onClick={() => this._saveSingleField("trade1_id", null)}
                      >
                        <DeleteIcon />
                      </IconButton> */}
                    </>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    disabled={true}
                    id="standard-name"
                    label="Trade Allowance"
                    value={
                      record.trade1 &&
                      formatNumberToStringMoney(record.trade1_allowance)
                    }
                    margin="normal"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    disabled={true}
                    id="standard-name"
                    label="Trade ACV"
                    value={
                      record.trade1 &&
                      formatNumberToStringMoney(record.trade1.buy_net)
                    }
                    margin="normal"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    disabled={true}
                    id="standard-name"
                    label="Trade Payoff"
                    value={
                      record.trade1 &&
                      formatNumberToStringMoney(record.trade1.payoff_amount)
                    }
                    margin="normal"
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" colSpan={3}>
                  <Typography variant="h6" align="center">
                    Trade 2
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" colSpan={3}>
                  {record.trade2 && (
                    <>
                      {`${record.trade2.year} ${record.trade2.make} ${record.trade2.model} ${record.trade2.color}`}
                      {/* <IconButton
                        color="primary"
                        size="small"
                        onClick={() => this._saveSingleField("trade2_id", null)}
                      >
                        <DeleteIcon />
                      </IconButton> */}
                    </>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <TextField
                    disabled={true}
                    id="standard-name"
                    label="Trade Allowance"
                    value={
                      record.trade2 &&
                      formatNumberToStringMoney(record.trade2_allowance)
                    }
                    margin="normal"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    disabled={true}
                    id="standard-name"
                    label="Trade ACV"
                    value={
                      record.trade2 &&
                      formatNumberToStringMoney(record.trade2.buy_net)
                    }
                    margin="normal"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    disabled={true}
                    id="standard-name"
                    label="Trade Payoff"
                    value={
                      record.trade2 &&
                      formatNumberToStringMoney(record.trade2.payoff_amount)
                    }
                    margin="normal"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTradesOpen(!tradesOpen)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={feesOpen}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Fees</DialogTitle>
        <DialogContent>
          <DialogContentText>Fees associated to this deal</DialogContentText>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th">License Fee:</TableCell>
                <TableCell>
                  <Input
                    value={fees_license}
                    name="fees_license"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Title Fee:</TableCell>
                <TableCell>
                  <Input
                    value={fees_title}
                    name="fees_title"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th">Filing Fee:</TableCell>
                <TableCell>
                  <Input
                    value={fees_filing}
                    name="fees_filing"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Other Fee:</TableCell>
                <TableCell>
                  <Input
                    value={fees_other}
                    name="fees_other"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Doc Fee (taxable):</TableCell>
                <TableCell>
                  <Input
                    value={fees_doc}
                    name="fees_doc"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Lender Fee:</TableCell>
                <TableCell>
                  <Input
                    value={fees_lender_processing}
                    name="fees_lender_processing"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeesOpen(!feesOpen)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={serviceContractOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Service Contract</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Service Contract</DialogContentText> */}
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th">
                  Service / Warranty Company:
                </TableCell>
                <TableCell>
                  <Select
                    name="service_contract_company_id"
                    value={service_contract_company_id}
                    onChange={onChangeDealProperty}
                  >
                    <MenuItem value="">--Service Company--</MenuItem>
                    {serviceCompanies.map((sc, index) => {
                      return (
                        <MenuItem key={sc.id} value={sc.id}>
                          {sc.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Term:</TableCell>
                <TableCell>
                  <Input
                    value={service_contract_term}
                    name="service_contract_term"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Miles:</TableCell>
                <TableCell>
                  <Input
                    value={service_contract_mileage}
                    name="service_contract_mileage"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Price:</TableCell>
                <TableCell>
                  <Input
                    value={service_contract_price}
                    name="service_contract_price"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Internal Cost:</TableCell>
                <TableCell>
                  <Input
                    value={service_contract_cost}
                    name="service_contract_cost"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setServiceContractOpen(!serviceContractOpen)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={gapOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Gap Contract</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Service Contract</DialogContentText> */}
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th">Gap Company</TableCell>
                <TableCell component="th">
                  <Select
                    name="gap_company_id"
                    value={gap_company_id}
                    onChange={onChangeDealProperty}
                  >
                    <MenuItem value="">--Gap Company--</MenuItem>
                    {gapCompanies.map((gc, index) => {
                      return (
                        <MenuItem key={gc.id} value={gc.id}>
                          {gc.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Term:</TableCell>
                <TableCell>
                  <Input
                    value={gap_term}
                    name="gap_term"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Price:</TableCell>
                <TableCell>
                  <Input
                    value={gap_price}
                    name="gap_price"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Internal Cost:</TableCell>
                <TableCell>
                  <Input
                    value={gap_cost}
                    name="gap_cost"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGapOpen(!gapOpen)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={accessoriesOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Accessories{" "}
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            m={20}
            onClick={onAddAccessory}
          >
            <AddIcon />
          </Fab>
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell component="th">Name</TableCell>
                <TableCell component="th">Price</TableCell>
                <TableCell component="th">Internal Cost</TableCell>
                <TableCell component="th">Taxable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {line_items.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={e => onDeleteAccessory(e, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Input
                        value={item.name}
                        name="name"
                        onChange={e => onChangeAccessoryProperty(e, item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        value={item.amount}
                        name="amount"
                        onChange={e => onChangeAccessoryProperty(e, item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        value={item.cost}
                        name="cost"
                        onChange={e => onChangeAccessoryProperty(e, item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={item.is_taxable}
                        onChange={e => onChangeAccessoryProperty(e, item.id)}
                        name="is_taxable"
                        value={true}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAccessoriesOpen(!accessoriesOpen)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={taxOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Tax Details</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Service Contract</DialogContentText> */}
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th">Tax Rate:</TableCell>
                <TableCell>
                  <Input
                    value={tax_rate}
                    name="tax_rate"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Total Tax:</TableCell>
                <TableCell>{total_tax}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTaxOpen(!taxOpen)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog open={financeInterestOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Finance Interest Details
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th">APR:</TableCell>
                <TableCell>
                  <Input
                    value={finance_apr}
                    name="finance_apr"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Addon Rate:</TableCell>
                <TableCell>
                  <Input
                    value={finance_addon_rate}
                    name="finance_addon_rate"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Contract APR:</TableCell>
                <TableCell>
                  <Input
                    value={finance_contract_apr}
                    name="finance_contract_apr"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Participation Rate:</TableCell>
                <TableCell>
                  <Input
                    value={finance_participation_rate}
                    name="finance_participation_rate"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Buy Rate:</TableCell>
                <TableCell>
                  <Input
                    value={finance_buy_rate}
                    name="finance_buy_rate"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Interest Spread:</TableCell>
                <TableCell>
                  <Input
                    value={finance_interest_spread}
                    name="finance_interest_spread"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">Flat Reserve:</TableCell>
                <TableCell>
                  <Input
                    value={finance_flat_reserve}
                    name="finance_flat_reserve"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setFinanceInterestOpen(!financeInterestOpen)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
