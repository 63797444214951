import React from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { history, hashHistory } from "../_helpers";

import { Doughnut } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

import { dashboardActions } from "../_actions";

import { PageWithAppDrawer } from "../_components/main/PageWithAppDrawer";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    const { leadGraphData: data } = props.dashboard;
    console.log(data);
    this.state = {
      donut: {
        labels: ["Open", "Sold", "Closed"],
        datasets: [
          {
            label: "My Leads",
            data: [data.open, data.sold, data.closed],
            backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C"],
            hoverBackgroundColor: ["#FF5A5E", "#5AD3D1", "#FFC870"]
          }
        ]
      }
    };
  }
  componentDidMount() {
    const { user } = this.props;
  }

  render() {
    // const { leadGraphData } = this.props.dashboard;
    console.log(this.props.dashboard);

    return (
      <div>
        <PageWithAppDrawer pageTitle={"Dashboard"} showMenu={true}>
          <MDBContainer>
            <h3 className="mt-5">My Leads</h3>
            <Doughnut
              data={this.state.donut}
              options={{ responsive: true }}
              getElementAtEvent={(elems, event) => {
                hashHistory.push("/myLeads");
              }}
            />
          </MDBContainer>
        </PageWithAppDrawer>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
});

function mapStateToProps(state) {
  const { dms, dashboard } = state;
  return {
    dms,
    dashboard
  };
}

const connectedDashboardPage = connect(mapStateToProps)(
  withStyles(styles)(DashboardPage)
);
export { connectedDashboardPage as DashboardPage };
