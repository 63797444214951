import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { alertActions } from "../_actions";
import { userActions } from "../_actions";

import { hashHistory } from "../_helpers";
import { PrivateRoute } from "../_components";
import { LoginPage } from "../LoginPage/LoginPage.js";
import { RegisterPage } from "../RegisterPage";
import { UsersPage } from "../Pages/UsersPage";
import { DealPage } from "../DealPage";
import { AutoLoginPage } from "../DealPage/AutoLoginPage.jsx";

import { DashboardPage } from "../Pages/DashboardPage";
import { PrintPage } from "../_components/PrintPage";
import { MyLeadsPage } from "../Pages/MyLeadsPage";
import { LienholdersPage } from "../Pages/LienholdersPage";
import { InventoryMaster } from "../Pages/InventoryMaster";
import { InventoryOpen } from "../Pages/InventoryOpen";
// import { InventoryClosed } from "../Pages/InventoryClosed";

class App extends Component {
  constructor(props) {
    super(props);

    hashHistory.listen((location, action) => {
      console.log("history.listen");
      // clear alert on location change
    });
  }

  componentDidMount() {
    const { dispatch } = this.props;

    hashHistory.listen((location, action) => {
      console.log("history.listen");
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  render() {
    const { alert } = this.props;
    return (
      // {alert.message && (
      //   <div className={`alert ${alert.type}`}>{alert.message}</div>
      // )}
      <Switch>
        <PrivateRoute exact path="/users" component={UsersPage} />
        <PrivateRoute exact path="/lienholders" component={LienholdersPage} />
        <PrivateRoute exact path="/myLeads" component={MyLeadsPage} />
        <PrivateRoute
          exact
          path="/inventoryMaster"
          component={InventoryMaster}
        />
        <PrivateRoute exact path="/inventoryOpen" component={InventoryOpen} />
        <PrivateRoute exact path="/" component={DashboardPage} />

        <PrivateRoute exact path="/print/:page" component={PrintPage} />

        <Route exact path="/deal/:recordId" component={DealPage} />
        <Route exact path="/myLeads/:salesperson_id" component={MyLeadsPage} />
        <Route exact path="/autologin" component={AutoLoginPage} />
        <Route path="/register" component={RegisterPage} />
        <Route path="/login" component={LoginPage} />
        <PrivateRoute exact path="/" component={DashboardPage} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { autoLogin } = state.authentication;
  return {
    alert,
    autoLogin
  };
}

const connectedApp = connect(mapStateToProps)(App);

export default withRouter(connectedApp);
