import { authHeader } from "../_helpers";

export const dmsService = {
  getById,
  dealUpdate,
  saveRecord,
  lineItemDelete,
  lineItemAdd,
  getInventory,
  getGapCompanies,
  getServiceCompanies,
  getDealers,
  getDocumentTypes,
  getSalespersons,
  getLeadSources,
  getLienholders,
  getLeads,
  setLead,
  vinDecode,
  removeTrade,
  addTrade
};

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  console.log(url);
  return fetch(`${url}/api/deals/${id}`, requestOptions)
    .then(handleResponse)
    .then(response => {
      let record = response;
      return record;
    });
}

function dealUpdate(recordId, deal, line_items) {
  const updateValues = {
    deal: deal,
    line_items: line_items
  };

  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(updateValues)
  };

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  return fetch(`${url}/api/deals/${recordId}`, requestOptions)
    .then(handleResponse)
    .then(response => {
      const record = response;
      return record;
    });
}

function saveRecord(record) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(record)
  };

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  return fetch(`${url}/api/records/${record.id}`, requestOptions)
    .then(handleResponse)
    .then(response => {
      const record = response;
      return record;
    });
}

function lineItemDelete(line_item) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() }
  };

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/records/${line_item.record_id}/line_items/${line_item.id}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      return;
    });
}

function lineItemAdd(record) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() }
  };

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  return fetch(`${url}/api/records/${record.id}/line_items`, requestOptions)
    .then(handleResponse)
    .then(response => {
      const line_item = response;
      return line_item;
    });
}

function getInventory(
  status = "available",
  filters = [],
  relations = [],
  perPage = 10,
  page = 1
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  console.log("filterString", filterString);
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;
  console.log("relationString", relationString);

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  console.log(url);
  return fetch(
    `${url}/api/inventory?status=${status}&per_page=${perPage}&page=${page}&${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      let inventory = response;
      return inventory;
    });
}

function getGapCompanies(filters = [], relations = [], perPage = 10, page = 1) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/gap_companies?per_page=${perPage}&page=${page}&${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      if (response.data) return response.data;
      return [];
    });
}

function getDealers(filters = [], relations = [], perPage = 10, page = 1) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/dealers?per_page=${perPage}&page=${page}&${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      if (response.data) return response.data;
      return [];
    });
}

function getServiceCompanies(
  filters = [],
  relations = [],
  perPage = 10,
  page = 1
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/service_companies?per_page=${perPage}&page=${page}&${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      if (response.data) return response.data;
      return [];
    });
}

function getDocumentTypes(
  filters = [],
  relations = [],
  perPage = 10,
  page = 1
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/document-types?per_page=${perPage}&page=${page}&${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      if (response.data) return response.data;
      return [];
    });
}

function getSalespersons(filters = [], relations = [], perPage = 10, page = 1) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/salespersons?order_by[]=first_name|asc&per_page=${perPage}&page=${page}&${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      if (response.data) return response.data;
      return [];
    });
}

function getLienholders(filters = [], relations = [], perPage = 10, page = 1) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/lienholders?order_by[]=name|asc&per_page=${perPage}&page=${page}&${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

function getLeadSources(filters = [], relations = [], perPage = 10, page = 1) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/lead-sources?order_by[]=name|asc&per_page=${perPage}&page=${page}&${filterString}&${relationString}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      if (response.data) return response.data;
      return [];
    });
}

function getLeads(filters = [], relations = [], perPage = 10, page = 1) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  const filterStrings = buildFilters(filters);
  const filterString = filterStrings.join("&");
  const relationStrings = buildRelations(relations);
  const relationString =
    relationStrings != null ? relationStrings.join("&") : null;

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/crm_emails?per_page=${perPage}&page=${page}&${filterString}&${relationString}&order_by[]=created_at|desc`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      if (response.data) return response;
      return [];
    });
}
function setLead(lead, data) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };

  const url = "https://api2.autonerdusa.com";
  return fetch(`${url}/api/crm_emails/${lead.id}`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

function vinDecode(vin) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  const url = "https://api2.autonerdusa.com";
  return fetch(`${url}/api/utils/vin-decode/${vin}`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

function removeTrade(record, tradeIndex) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/records/${record.id}/remove-trade/${tradeIndex}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

function addTrade(record, tradeIndex, data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data)
  };

  const url = "https://api2.autonerdusa.com";
  return fetch(
    `${url}/api/records/${record.id}/add-trade/${tradeIndex}`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      return response;
    });
}

function buildRelations(relations) {
  if (relations == null) return [];
  if (relations.length == 0) return [];
  let strings = [];
  relations.forEach(function(relation, index) {
    strings.push(`with[]=${relation}`);
  });
  return strings;
}

function buildFilters(filters) {
  if (filters == null) return [];
  if (filters.length == 0) return [];
  let strings = [];
  filters.forEach(function(object, index) {
    const { field, operator, value } = object;
    strings.push(`filters[${index}]=${field}|${operator}|${value}`);
  });
  return strings;
}

function handleResponse(response) {
  // console.log("dms response", response);
  return response.text().then(text => {
    try {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          console.log("not authorized", 401);
          return Promise.reject("Not Authorized");
        }
        console.log("response not okay");

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      // console.log("response okay");

      return data.response ? data.response : data;
    } catch (e) {
      return Promise.reject(e.message);
    }
  });
}
