import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export function ModalSalespersons(props) {
  const {
    onClose,
    open,
    record,
    salespersonsList,
    onChangeRecordProperty
  } = props;

  if (!record)
    return (
      <Dialog onClose={onClose} open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Salespersons</DialogTitle>
      </Dialog>
    );

  return (
    <Dialog onClose={onClose} open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Salespersons</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th">Salesperson 1:</TableCell>
              <TableCell>
                <Select
                  name="salesperson_id"
                  value={record.salesperson_id || ""}
                  onChange={onChangeRecordProperty}
                >
                  <MenuItem key={-1} value={""}>
                    --NONE--
                  </MenuItem>
                  {salespersonsList.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Salesperson 2:</TableCell>
              <TableCell>
                <Select
                  name="salesperson2_id"
                  value={record.salesperson2_id || ""}
                  onChange={onChangeRecordProperty}
                >
                  <MenuItem key={-1} value={""}>
                    --NONE--
                  </MenuItem>
                  {salespersonsList.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Salesperson 3:</TableCell>
              <TableCell>
                <Select
                  name="salesperson3_id"
                  value={record.salesperson3_id || ""}
                  onChange={onChangeRecordProperty}
                >
                  <MenuItem key={-1} value={""}>
                    --NONE--
                  </MenuItem>
                  {salespersonsList.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
