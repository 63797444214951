import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { dmsService } from "../../_services";

// import {
//   formatNumberToStringMoney,
//   recordFeesTotal,
//   recordAmountFinanced,
//   recordCashDealTotal,
//   recordTotalTax,
//   recordTaxableAmount,
//   recordAccessoriesTotal,
//   recordFrontGross,
//   recordMonthlyPayment
// } from "../_helpers";

import { dmsActions } from "../../_actions";
import "./Deal.css";
import DealRecapTable from "./DealRecapTable";
import DealBasicInfo from "./DealBasicInfo";
import DealBuyerInfo from "./DealBuyerInfo";
import DealTrades from "./DealTrades";

import DealDetails from "./DealDetails";
import DealDocuments from "./DealDocuments";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

class Deal extends PureComponent {
  constructor(props) {
    super(props);
    this._update = this._update.bind(this);
    this._change = this._change.bind(this);
    this._changeDeal = this._changeDeal.bind(this);

    // this._updateLineItem = this._updateLineItem.bind(this);
    this._changeLineItem = this._changeLineItem.bind(this);
    this._lineItemDelete = this._lineItemDelete.bind(this);
    this._addLineItem = this._addLineItem.bind(this);
    this._saveSingleField = this._saveSingleField.bind(this);

    this.state = {
      tabIndex: 1,

      arMoney: [
        "price",
        "fees_license",
        "fees_title",
        "fees_filing",
        "fees_other",
        "fees_doc",
        "fees_lender_processing"
      ]
    };
  }

  formatNumber = number => {
    // console.log("formatNumber", number);
    if (isNaN(number)) return 0;
    return Number(number).toString();
  };

  changeTab = (e, newValue) => {
    if (newValue == 0) {
      this._update();
    } else {
      this.setState({ tabIndex: newValue });
    }
  };

  render() {
    // const { loadingLineItems } = this.props;
    const { items: dealerList } = this.props.dms.dealers;
    const { items: documentTypes } = this.props.dms.documentTypes;
    const { items: gapCompanies } = this.props.dms.gapCompanies;
    const { items: serviceCompanies } = this.props.dms.serviceCompanies;
    const { items: salespersonsList } = this.props.dms.salespersons;
    const { items: leadSourcesList } = this.props.dms.leadSources;
    const { items: lienholders } = this.props.dms.lienholders;

    const { record } = this.props || {};
    // const { deal } = record || {};
    // const line_items = record && record.line_items ? record.line_items : [];

    const { tabIndex } = this.state;

    // console.log(documentTypes);

    return (
      <div>
        <AppBar position="static">
          <Tabs
            value={tabIndex}
            onChange={this.changeTab}
            variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label="Save Changes" />
            <Tab label="Basic Info" />
            <Tab label="Buyer Info" />
            <Tab label="Trades" />
            <Tab label="Deal Details" />
            <Tab label="Recap Sheet" />
            <Tab label="Documents" />
          </Tabs>
        </AppBar>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 1}
          id={`simple-tabpanel-1`}
          aria-labelledby={`simple-tab-1`}
        >
          {record && (
            <DealBasicInfo
              record={record}
              onChangeRecordProperty={this._changeRecord}
              onChangeInventoryProperty={this._changeInventory}
              onChangeDealProperty={this._changeDeal}
              dealerList={dealerList}
              salespersonsList={salespersonsList || []}
            />
          )}
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 2}
          id={`simple-tabpanel-2`}
          aria-labelledby={`simple-tab-2`}
        >
          {record && (
            <DealBuyerInfo
              record={record}
              onChangeRecordProperty={this._changeRecord}
              onChangeInventoryProperty={this._changeInventory}
              onChangeDealProperty={this._changeDeal}
              dealerList={dealerList}
              leadSourcesList={leadSourcesList}
            />
          )}
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 3}
          id={`simple-tabpanel-3`}
          aria-labelledby={`simple-tab-3`}
        >
          {record && (
            <DealTrades
              record={record}
              onRefreshRecord={this.handleRefreshRecord}
            />
          )}
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 4}
          id={`simple-tabpanel-4`}
          aria-labelledby={`simple-tab-4`}
        >
          {record && (
            <DealDetails
              record={this.props.record}
              gapCompanies={gapCompanies}
              serviceCompanies={serviceCompanies}
              lienholders={lienholders}
              onChangeRecordProperty={this._changeRecord}
              onChangeInventoryProperty={this._changeInventory}
              onChangeDealProperty={this._changeDeal}
              onChangeAccessoryProperty={this._changeLineItem}
              onAddAccessory={this._addLineItem}
              onDeleteAccessory={this._lineItemDelete}
            />
          )}
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 5}
          id={`simple-tabpanel-5`}
          aria-labelledby={`simple-tab-5`}
        >
          {this.props.record && <DealRecapTable record={this.props.record} />}
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 6}
          id={`simple-tabpanel-6`}
          aria-labelledby={`simple-tab-6`}
        >
          <DealDocuments record={record} documentTypes={documentTypes} />
        </Typography>
      </div>
    );
  }

  handleRefreshRecord = () => {
    const { record } = this.props;
    this.props.dispatch(dmsActions.getDeal(record.id));
  };

  _change(e) {
    const { arMoney } = this.state;

    const type = e.target.type;
    const name = e.target.name;
    let value = type === "checkbox" ? e.target.checked : e.target.value;

    if (arMoney.includes(name) && value !== "") {
      let regexValidNumber = /^[-]*[0-9]+(\.[0-9]{0,2})?$/;
      if (!regexValidNumber.test(value)) return;
    }
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value
      }
    });
  }

  _changeDeal = e => {
    const { arMoney } = this.state;

    const type = e.target.type;
    const name = e.target.name;
    let value = type === "checkbox" ? e.target.checked : e.target.value;

    if (arMoney.includes(name) && value !== "") {
      let regexValidNumber = /^[-]*[0-9]+(\.[0-9]{0,2})?$/;
      if (!regexValidNumber.test(value)) return;
    }
    this.props.dispatch(dmsActions.setCurrentDealValue(name, value));
    // this.props.dispatch(dmsService.dealUpdate());
  };

  _changeRecord = (e, overrideField, overrideValue) => {
    // console.log("_changeRecord", e.target.name);
    const { arMoney } = this.state;

    let type = null;
    let name = null;
    let value = null;
    if (typeof overrideField == "string") {
      name = overrideField;
      value = overrideValue;
    } else {
      type = e.target.type;
      name = e.target.name;
      value = type === "checkbox" ? e.target.checked : e.target.value;
    }

    if (arMoney.includes(name) && value !== "") {
      let regexValidNumber = /^[-]*[0-9]+(\.[0-9]{0,2})?$/;
      if (!regexValidNumber.test(value)) return;
    }

    console.log("_changeRecord", { name, value });

    this.props.dispatch(dmsActions.setCurrentRecordValue(name, value));
    // this.props.dispatch(dmsService.dealUpdate());
  };

  _changeInventory = e => {
    const { arMoney } = this.state;

    const type = e.target.type;
    const name = e.target.name;
    let value = type === "checkbox" ? e.target.checked : e.target.value;

    if (arMoney.includes(name) && value !== "") {
      let regexValidNumber = /^[-]*[0-9]+(\.[0-9]{0,2})?$/;
      if (!regexValidNumber.test(value)) return;
    }
    this.props.dispatch(dmsActions.setCurrentInventoryValue(name, value));
    // this.props.dispatch(dmsService.dealUpdate());
  };

  _changeLineItem = (e, id) => {
    const { arMoney } = this.state;

    const type = e.target.type;
    const name = e.target.name;
    let value = type === "checkbox" ? e.target.checked : e.target.value;

    if (arMoney.includes(name) && value !== "") {
      let regexValidNumber = /^[-]*[0-9]+(\.[0-9]{0,2})?$/;
      if (!regexValidNumber.test(value)) return;
    }
    this.props.dispatch(dmsActions.setCurrentLineItemValue(id, name, value));
    // this.props.dispatch(dmsService.dealUpdate());
  };

  _update(e) {
    const { onSaveRecord } = this.props;
    // const { record } = this.props;

    onSaveRecord();
  }

  _saveSingleField(name, value) {
    const { onSaveField } = this.props;
    onSaveField(name, value);
  }

  _addLineItem() {
    const { onLineItemAdd } = this.props;

    onLineItemAdd();
  }

  _searchVin(e, targetField) {
    const key = e.key;
    if (key !== "Enter") return;

    const type = e.target.type;
    // const name = e.target.name;
    const value = type === "checkbox" ? e.target.checked : e.target.value;

    const filters = [
      {
        field: "vin",
        operator: "like",
        value: value
      },
      {
        field: "status",
        operator: "eq",
        value: 1
      }
    ];

    dmsService.getInventory(null, filters).then(
      inventory => {
        if (inventory.total == 0) {
          const addTrade = window.confirm(
            value + " not in DMS\nAdd this as a trade?"
          );
          if (addTrade) {
            this.props.dispatch(dmsService.dealUpdate());
          }
        }

        if (inventory.total == 1) {
          window.confirm(value + " already in Inventory\nAdd this as a trade?");
        }
        if (inventory.total > 1)
          alert("multiple values, please refine your search");
      },
      error => {
        console.log(error);
      }
    );
  }

  _lineItemDelete = (e, index) => {
    const { onLineItemDelete } = this.props;
    const line_item = this.props.line_items[index];
    onLineItemDelete(line_item);
  };
}

function mapStateToProps(state) {
  const { dms } = state;
  return {
    dms
  };
}

const connectedDeal = connect(mapStateToProps)(Deal);
export { connectedDeal as Deal };
