import React from "react";
import { Link } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

export const mainListItems = (clickHandler = null, isAdmin = false) => (
  <div>
    <ListItem component={Link} to="/" button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem component={Link} to="/myLeads" button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="My Leads" />
    </ListItem>
    <ListItem component={Link} to="/inventoryMaster" button>
      <ListItemIcon>
        <MonetizationOnIcon />
      </ListItemIcon>
      <ListItemText primary="Inventory Master" />
    </ListItem>
    <ListItem component={Link} to="/inventoryOpen" button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Open Inventory" />
    </ListItem>
    <ListItem component={Link} to="/inventoryClosed" button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Closed Inventory" />
    </ListItem>
    <ListItem component={Link} to="/lienholders" button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Lienholders" />
    </ListItem>
    {isAdmin && (
      <ListItem component={Link} to="/users" button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItem>
    )}
    <ListItem component={Link} to="/login" button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </div>
);

export const secondaryListItems = (clickHandler = null, isAdmin = false) => (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    {!isAdmin && (
      <div>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Current month" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Last quarter" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Year-end sale" />
        </ListItem>
      </div>
    )}
  </div>
);

export const externalListItems = (clickHandler = null, isAdmin = false) => (
  <div>
    <ListSubheader inset>App Launcher</ListSubheader>
    <div>
      <ListItem onClick={() => clickHandler("Excel")} button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Excel" />
      </ListItem>
      <ListItem onClick={() => clickHandler("Word")} button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Word" />
      </ListItem>
      <ListItem onClick={() => clickHandler("PowerPoint")} button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="PowerPoint" />
      </ListItem>
    </div>
  </div>
);
