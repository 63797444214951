import { dmsConstants } from "../_constants";
import { dmsService } from "../_services";
import { alertActions } from ".";

export const dmsActions = {
  getDeal,
  dealUpdate,
  lineItemDelete,
  lineItemAdd,
  getInventory,
  getGapCompanies,
  getServiceCompanies,
  getDealers,
  getDocumentTypes,
  getLeads,
  getSalespersons,
  getLeadSources,
  getLienholders,
  setLead,
  setCurrentRecordValue,
  setCurrentInventoryValue,
  setCurrentDealValue,
  setCurrentLineItemValue,
  saveRecord
};

function getDeal(recordId) {
  return dispatch => {
    dispatch(request({ recordId }));

    dmsService.getById(recordId).then(
      record => {
        dispatch(success(record));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(recordId) {
    return { type: dmsConstants.DEAL_GETID_REQUEST, recordId };
  }
  function success(record) {
    return { type: dmsConstants.DEAL_GETID_SUCCESS, record };
  }
  function failure(error) {
    return { type: dmsConstants.DEAL_GETID_FAILURE, error };
  }
}

function dealUpdate(recordId, deal, line_items) {
  return dispatch => {
    dispatch(request());

    dmsService.dealUpdate(recordId, deal, line_items).then(
      record => {
        dispatch(success(record));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.DEAL_UPDATEID_REQUEST };
  }
  function success(record) {
    return { type: dmsConstants.DEAL_UPDATEID_SUCCESS, record };
  }
  function failure(error) {
    return { type: dmsConstants.DEAL_UPDATEID_FAILURE, error };
  }
}

function lineItemDelete(line_item) {
  return dispatch => {
    dispatch(request());

    dmsService.lineItemDelete(line_item).then(
      record => {
        dispatch(success(line_item));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.LINEITEM_DELETE_REQUEST };
  }
  function success(line_item) {
    return { type: dmsConstants.LINEITEM_DELETE_SUCCESS, line_item };
  }
  function failure(error) {
    return { type: dmsConstants.LINEITEM_DELETE_FAILURE, error };
  }
}

function lineItemAdd(record = null) {
  return dispatch => {
    dispatch(request());

    dmsService.lineItemAdd(record).then(
      line_item => {
        dispatch(success(line_item));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.LINEITEM_ADD_REQUEST };
  }
  function success(line_item) {
    return { type: dmsConstants.LINEITEM_ADD_SUCCESS, line_item };
  }
  function failure(error) {
    return { type: dmsConstants.LINEITEM_ADD_FAILURE, error };
  }
}

function getInventory(
  status = "available",
  filters = [],
  relations = [],
  perPage = 10,
  page = 1
) {
  return dispatch => {
    dispatch(request(status));

    dmsService.getInventory(status, filters, relations, perPage, page).then(
      inventory => {
        dispatch(success(status, inventory));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(status, error.toString()));
      }
    );
  };

  function request(status) {
    return { type: dmsConstants.INVENTORY_GET_REQUEST, status };
  }
  function success(status, inventory) {
    return { type: dmsConstants.INVENTORY_GET_SUCCESS, status, inventory };
  }
  function failure(status, error) {
    return { type: dmsConstants.INVENTORY_GET_FAILURE, status, error };
  }
}

function getGapCompanies(filters = [], relations = [], perPage = 10, page = 1) {
  return dispatch => {
    dispatch(request());

    dmsService.getGapCompanies(filters, relations, perPage, page).then(
      gapCompanies => {
        dispatch(success(gapCompanies));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.GAPCOMPANIES_GET_REQUEST };
  }
  function success(gapCompanies) {
    return { type: dmsConstants.GAPCOMPANIES_GET_SUCCESS, gapCompanies };
  }
  function failure(status, error) {
    return { type: dmsConstants.GAPCOMPANIES_GET_FAILURE, error };
  }
}

function getServiceCompanies(
  filters = [],
  relations = [],
  perPage = 10,
  page = 1
) {
  return dispatch => {
    dispatch(request());

    dmsService.getServiceCompanies(filters, relations, perPage, page).then(
      serviceCompanies => {
        dispatch(success(serviceCompanies));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.SERVICECOMPANIES_GET_REQUEST };
  }
  function success(serviceCompanies) {
    return {
      type: dmsConstants.SERVICECOMPANIES_GET_SUCCESS,
      serviceCompanies
    };
  }
  function failure(status, error) {
    return { type: dmsConstants.SERVICECOMPANIES_GET_FAILURE, error };
  }
}

function getDealers(filters = [], relations = [], perPage = 10, page = 1) {
  return dispatch => {
    dispatch(request());

    dmsService.getDealers(filters, relations, perPage, page).then(
      dealers => {
        dispatch(success(dealers));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.DEALERS_GET_REQUEST };
  }
  function success(dealers) {
    return {
      type: dmsConstants.DEALERS_GET_SUCCESS,
      dealers
    };
  }
  function failure(status, error) {
    return { type: dmsConstants.DEALERS_GET_FAILURE, error };
  }
}

function getDocumentTypes(
  filters = [],
  relations = [],
  perPage = 10,
  page = 1
) {
  return dispatch => {
    dispatch(request());

    dmsService.getDocumentTypes(filters, relations, perPage, page).then(
      documentTypes => {
        dispatch(success(documentTypes));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.DOCTYPES_GET_REQUEST };
  }
  function success(documentTypes) {
    return {
      type: dmsConstants.DOCTYPES_GET_SUCCESS,
      documentTypes
    };
  }
  function failure(status, error) {
    return { type: dmsConstants.DOCTYPES_GET_FAILURE, error };
  }
}

function getSalespersons(filters = [], relations = [], perPage = 10, page = 1) {
  return dispatch => {
    dispatch(request());

    dmsService.getSalespersons(filters, relations, perPage, page).then(
      salespersons => {
        dispatch(success(salespersons));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.SALESPERSONS_GET_REQUEST };
  }
  function success(salespersons) {
    return {
      type: dmsConstants.SALESPERSONS_GET_SUCCESS,
      salespersons
    };
  }
  function failure(status, error) {
    return { type: dmsConstants.SALESPERSONS_GET_FAILURE, error };
  }
}

function getLienholders(filters = [], relations = [], perPage = 10, page = 1) {
  return dispatch => {
    dispatch(request());

    dmsService.getLienholders(filters, relations, perPage, page).then(
      lienholders => {
        dispatch(success(lienholders));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.LIENHOLDERS_GET_REQUEST };
  }
  function success(lienholders) {
    return {
      type: dmsConstants.LIENHOLDERS_GET_SUCCESS,
      lienholders
    };
  }
  function failure(status, error) {
    return { type: dmsConstants.LIENHOLDERS_GET_FAILURE, error };
  }
}

function getLeads(
  leadType,
  filters = [],
  relations = [],
  perPage = 10,
  page = 1
) {
  return dispatch => {
    dispatch(request(leadType));

    dmsService.getLeads(filters, relations, perPage, page).then(
      leads => {
        dispatch(success(leads, leadType));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(leadType) {
    return { type: dmsConstants.LEADS_GET_REQUEST, leadType };
  }
  function success(leads, leadType) {
    return {
      type: dmsConstants.LEADS_GET_SUCCESS,
      leads,
      leadType
    };
  }
  function failure(status, error) {
    return { type: dmsConstants.LEADS_GET_FAILURE, error };
  }
}

function getLeadSources(filters = [], relations = [], perPage = 10, page = 1) {
  return dispatch => {
    dispatch(request());

    dmsService.getLeadSources(filters, relations, perPage, page).then(
      leadSources => {
        dispatch(success(leadSources));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.LEADSOURCES_GET_REQUEST };
  }
  function success(leadSources) {
    return {
      type: dmsConstants.LEADSOURCES_GET_SUCCESS,
      leadSources
    };
  }
  function failure(status, error) {
    return { type: dmsConstants.LEADSOURCES_GET_FAILURE, error };
  }
}

function setLead(leadType, lead, data) {
  return dispatch => {
    dispatch(request());

    dmsService.setLead(lead, data).then(
      lead => {
        dispatch(success(lead, leadType));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.LEAD_SET_REQUEST };
  }
  function success(lead, leadType) {
    return {
      type: dmsConstants.LEAD_SET_SUCCESS,
      lead,
      leadType
    };
  }
  function failure(status, error) {
    return { type: dmsConstants.LEAD_SET_FAILURE, error };
  }
}

function setCurrentRecordValue(field, value) {
  return dispatch => {
    dispatch(request(field, value));
  };

  function request(field, value) {
    return {
      type: dmsConstants.CURRENTRECORD_SET,
      field,
      value
    };
  }
}

function setCurrentInventoryValue(field, value) {
  return dispatch => {
    dispatch(request(field, value));
  };

  function request(field, value) {
    return {
      type: dmsConstants.CURRENTINVENTORY_SET,
      field,
      value
    };
  }
}

function setCurrentDealValue(field, value) {
  return dispatch => {
    dispatch(request(field, value));
  };

  function request(field, value) {
    return {
      type: dmsConstants.CURRENTDEAL_SET,
      field,
      value
    };
  }
}

function setCurrentLineItemValue(id, field, value) {
  return dispatch => {
    dispatch(request(id, field, value));
  };

  function request(id, field, value) {
    return {
      type: dmsConstants.CURRENTLINEITEM_SET,
      id,
      field,
      value
    };
  }
}

function saveRecord(record) {
  return dispatch => {
    dispatch(request());

    dmsService.saveRecord(record).then(
      record => {
        dispatch(success(record));
      },
      error => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: dmsConstants.SAVE_RECORD_REQUEST };
  }
  function success(record) {
    return { type: dmsConstants.SAVE_RECORD_SUCCESS, record };
  }
  function failure(error) {
    return { type: dmsConstants.SAVE_RECORD_FAILURE, error };
  }
}
