import React from "react";
// import { makeStyles } from "@material-ui/core/styles";

import {
  formatNumberToStringMoney,
  // recordFeesTotal,
  // recordAmountFinanced,
  // recordCashDealTotal,
  // recordTotalTax,
  // recordTaxableAmount,
  // recordAccessoriesTotal,
  // recordFrontGross,
  // recordMonthlyPayment,
  // recordTradesAllowanceTotal,
  // recordFinanceReserveValue,
  recordBuyRateInterest,
  recordParticipationRateInterest
} from "../../_helpers";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export function ModalReserve(props) {
  const { onClose, open, record, onChangeDealProperty } = props;

  const buyRateInterest = recordBuyRateInterest(record);
  const participationRateInterest = recordParticipationRateInterest(record);
  const interestSpread = participationRateInterest - buyRateInterest;
  const earnedOnReserve =
    record.deal.finance_reserve_type == "percent of finance"
      ? interestSpread
      : (interestSpread *
          (100 - record.deal.finance_reserve_bank_hold_percent)) /
        100;

  if (!record)
    return (
      <Dialog onClose={onClose} open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reserve Calculation</DialogTitle>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog onClose={onClose} open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Reserve Calculation</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead></TableHead>
          <TableBody>
            {/* <TableRow>
              <TableCell component="th">Salesperson 2:</TableCell>
              <TableCell>
                <Input
                  value={fees_license}
                  name="fees_license"
                  onChange={onChangeDealProperty}
                />
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell component="th">APR:</TableCell>
              <TableCell>
                <Input
                  value={record.deal.finance_apr}
                  name="finance_apr"
                  onChange={onChangeDealProperty}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Addon Rate:</TableCell>
              <TableCell>
                <Input
                  value={record.deal.finance_addon_rate}
                  name="finance_addon_rate"
                  onChange={onChangeDealProperty}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Contract APR:</TableCell>
              <TableCell>
                <Input
                  value={record.deal.finance_contract_apr}
                  name="finance_contract_apr"
                  onChange={onChangeDealProperty}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Participation Rate:</TableCell>
              <TableCell>
                <Input
                  value={record.deal.finance_participation_rate}
                  name="finance_participation_rate"
                  onChange={onChangeDealProperty}
                />
              </TableCell>
              <TableCell>
                {formatNumberToStringMoney(participationRateInterest)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Buy Rate:</TableCell>
              <TableCell>
                <Input
                  value={record.deal.finance_buy_rate}
                  name="finance_buy_rate"
                  onChange={onChangeDealProperty}
                />
              </TableCell>
              <TableCell>
                {formatNumberToStringMoney(buyRateInterest)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Interest Spread:</TableCell>
              <TableCell>
                {/* <Input
                  value={record.deal.finance_interest_spread}
                  name="finance_interest_spread"
                  onChange={onChangeDealProperty}
                /> */}
                {formatNumberToStringMoney(interestSpread)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Flat Reserve:</TableCell>
              <TableCell>
                <Input
                  value={record.deal.finance_flat_reserve}
                  name="finance_flat_reserve"
                  onChange={onChangeDealProperty}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">Reserve Type:</TableCell>
              <TableCell>
                <Select
                  name="finance_reserve_type"
                  value={record.deal.finance_reserve_type}
                  onChange={onChangeDealProperty}
                >
                  <MenuItem value={"percent of finance"}>
                    Percent of Finance
                  </MenuItem>
                  <MenuItem value={"calculate on markup"}>
                    Calculate on markup from buy rate to sell rate
                  </MenuItem>
                </Select>
              </TableCell>
            </TableRow>
            {record.deal.finance_reserve_type == "percent of finance" && (
              <TableRow>
                <TableCell component="th">Percent of Finance:</TableCell>
                <TableCell>
                  <Input
                    value={record.deal.finance_reserve_percent_finance}
                    name="finance_reserve_percent_finance"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
            )}
            {record.deal.finance_reserve_type == "calculate on markup" && (
              <TableRow>
                <TableCell component="th">Bank hold percentage:</TableCell>
                <TableCell>
                  <Input
                    value={record.deal.finance_reserve_bank_hold_percent}
                    name="finance_reserve_bank_hold_percent"
                    onChange={onChangeDealProperty}
                  />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th">Amount Earned on Reserve</TableCell>
              <TableCell>
                {formatNumberToStringMoney(earnedOnReserve)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
