import React from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { dmsActions } from "../_actions";

import { PageWithAppDrawer } from "../_components/main/PageWithAppDrawer";
import MyLeadsTable2 from "./MyLeadsTable2";

// import Container from "@material-ui/core/Container";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

class MyLeadsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      modalOpen: false,
      modalTitle: null,
      modalContent: [],
      filters: []
    };
  }
  componentDidMount() {
    const { user } = this.props;
    const { salesperson_id } = this.props.match ? this.props.match.params : {};
    // console.log("salesperson_id", salesperson_id);

    const filters =
      salesperson_id === "all"
        ? []
        : [
            {
              field: "salesperson_id",
              operator: "eq",
              value: salesperson_id || user.salesperson.id
            }
          ];
    this.setState(
      {
        filters
      },
      () => {
        this.handleChangeData("open", 0, this.props.leads.open.per_page);
        this.handleChangeData("closed", 0, this.props.leads.closed.per_page);
        this.handleChangeData("sold", 0, this.props.leads.sold.per_page);
      }
    );
  }

  render() {
    // const { classes } = this.props;
    const {
      loading: loadingOpen,
      items: leadsOpen,
      page: pageOpen,
      per_page: perPageOpen,
      total: totalOpen
    } = this.props.leads.open;

    const {
      loading: loadingClosed,
      items: leadsClosed,
      page: pageClosed,
      per_page: perPageClosed,
      total: totalClosed
    } = this.props.leads.closed;

    const {
      loading: loadingSold,
      items: leadsSold,
      page: pageSold,
      per_page: perPageSold,
      total: totalSold
    } = this.props.leads.sold;

    const { tabIndex } = this.state;

    return (
      <PageWithAppDrawer pageTitle={"My Leads"}>
        <AppBar position="static">
          <Tabs
            value={tabIndex}
            onChange={(e, newValue) => {
              this.handleChangeTab(newValue);
            }}
            variant="scrollable"
            aria-label="simple tabs example"
          >
            <Tab label={"Open Leads"} />
            <Tab label={"Closed Leads"} />
            <Tab label={"Sold Leads"} />
            <Tab label={"Refresh"} />
          </Tabs>
        </AppBar>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 0}
          id={`simple-tabpanel-0`}
          aria-labelledby={`simple-tab-0`}
        >
          {!loadingOpen && (
            <MyLeadsTable2
              rows={leadsOpen}
              type={"open"}
              page={Number(pageOpen) - 1}
              per_page={Number(perPageOpen)}
              total={Number(totalOpen)}
              onUpdateLead={this.updateLead}
              onOpenModal={this.handleOpenModal}
              onChangeData={this.handleChangeData}
            />
          )}
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 1}
          id={`simple-tabpanel-1`}
          aria-labelledby={`simple-tab-1`}
        >
          {!loadingClosed && (
            <MyLeadsTable2
              rows={leadsClosed}
              type={"open"}
              page={Number(pageClosed) - 1}
              per_page={Number(perPageClosed)}
              total={Number(totalClosed)}
              onUpdateLead={this.updateLead}
              onOpenModal={this.handleOpenModal}
              onChangeData={this.handleChangeData}
            />
          )}
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 2}
          id={`simple-tabpanel-2`}
          aria-labelledby={`simple-tab-2`}
        >
          {!loadingSold && (
            <MyLeadsTable2
              rows={leadsSold}
              type={"open"}
              page={Number(pageSold) - 1}
              per_page={Number(perPageSold)}
              total={Number(totalSold)}
              onUpdateLead={this.updateLead}
              onOpenModal={this.handleOpenModal}
              onChangeData={this.handleChangeData}
            />
          )}
        </Typography>

        {/* <MyLeadsTable items={leads} onUpdateLead={this.updateLead} /> */}
      </PageWithAppDrawer>
    );
  }

  handleChangeTab = tabIndex => {
    if (tabIndex === 3) {
      this.handleChangeData("open", 0, this.props.leads.open.per_page);
      this.handleChangeData("closed", 0, this.props.leads.closed.per_page);
      this.handleChangeData("sold", 0, this.props.leads.sold.per_page);
    } else {
      this.setState({ tabIndex: tabIndex });
    }
  };

  handleChangeData = (type, page, per_page) => {
    page++;
    this.getNewData(type, page, per_page);
  };

  getNewData = (type, page = null, per_page = null) => {
    console.log("getNewData", type);
    if (!per_page) per_page = this.state.per_page;
    if (!page) page = this.state.page;
    let typeFilters = this.state.filters.slice(0);
    typeFilters.push({
      field: "status",
      operator: "eq",
      value: type
    });
    this.setState({
      page: page,
      per_page: per_page
    });
    console.log({ page, per_page });
    this.props.dispatch(
      dmsActions.getLeads(
        type, // open / closed / sold
        typeFilters, // filters
        null, // relations
        per_page, // per_page
        page // page
      )
    );
  };

  updateLead = (type, lead, data) => {
    this.props.dispatch(dmsActions.setLead(type, lead, data));
  };

  handleOpenModal = lead => {};
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%"
  },
  fixedHeight: {
    height: 240
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

function mapStateToProps(state) {
  const { dms, authentication } = state;
  const { user } = authentication;
  const { leads } = dms;
  return {
    user,
    dms,
    leads
  };
}

const connectedMyLeadsPage = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(MyLeadsPage))
);
export { connectedMyLeadsPage as MyLeadsPage };
