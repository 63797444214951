import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const defaultState = {
  user: null,
  loggedIn: false,
  loggingIn: false,
  autoLogin: false,
  selectedEnvironment: null,
  environments: [
    {
      name: 'Development',
      url: 'https://api2.autonerdusa.com'
    },
    {
      name: 'Production',
      url: 'https://api2.autonerdusa.com'
    }
  ]
}
const initialState = user ?
  {
    ...defaultState,
    loggedIn: true,
    user: user
  } :
  defaultState;

export function authentication(state = initialState, action) {
  switch (action.type) {

    case userConstants.SETENV:
      return {
        ...state,
        selectedEnvironment: action.environment,
      };
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        user: null,
      };
    default:
      return state
  }
}