import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { userActions } from '../_actions';
import './Dms.css';
// import VehicleCard from '../_components/VehicleCard';
// import Deal from '../_components/Deal';

class AutoLoginPage extends React.Component {
    componentDidMount() {
        const queryString = require('query-string');
        const parsed = queryString.parse(this.props.location.search);
        console.log(parsed);
        
        if (parsed.key == 'f4d4mg98'){
            const route = parsed.route || '/';
            localStorage.setItem('environment', JSON.stringify('Development'));
            this.props.dispatch(userActions.login('primordius@gmail.com', 'm0rtalp1', route));
        }
    }

    render() {
        const { dms } = this.props;
        const { loading } = dms;
        
        if (loading){
            return(
                <div>
                    <div>Loading...</div>
                    <p><Link to="/login">Logout</Link></p>
                </div>
            )
        }
        
        return (
            <div>
                We got a problem
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { dms, authentication } = state;
    const { user } = authentication;
    return {
        user,
        dms
    };
}

const connectedAutoLoginPage = connect(mapStateToProps)(AutoLoginPage);
export { connectedAutoLoginPage as AutoLoginPage };