import { dmsConstants } from "../_constants";

const initialState = {
  loading: false,
  loadingLineItems: false,
  currentRecord: null,
  inventory: {
    available: {
      loading: false,
      list: null,
      error: null,
      date: null
    },
    search: {
      loading: false,
      list: null,
      error: null,
      date: null
    },
    open: {
      loading: false,
      list: null,
      error: null,
      date: null
    }
  },
  gapCompanies: {
    loading: false,
    items: []
  },
  serviceCompanies: {
    loading: false,
    items: []
  },
  dealers: {
    loading: false,
    items: []
  },
  documentTypes: {
    loading: false,
    items: []
  },
  salespersons: {
    loading: false,
    items: []
  },
  leadSources: {
    loading: false,
    items: []
  },
  lienholders: {
    loading: false,
    items: [],
    page: 1,
    per_page: 10,
    total: 0
  },
  leads: {
    open: {
      loading: false,
      items: [],
      page: 1,
      per_page: 10,
      total: 0
    },
    closed: {
      loading: false,
      items: [],
      page: 1,
      per_page: 10,
      total: 0
    },
    sold: {
      loading: false,
      items: [],
      page: 1,
      per_page: 10,
      total: 0
    }
  }
};

export function dms(state = initialState, action) {
  switch (action.type) {
    case dmsConstants.DEAL_GETID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dmsConstants.DEAL_GETID_SUCCESS:
      return {
        ...state,
        currentRecord: action.record,
        loading: false
      };
    case dmsConstants.DEAL_GETID_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case dmsConstants.DEAL_UPDATEID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dmsConstants.DEAL_UPDATEID_SUCCESS:
      return {
        ...state,
        currentRecord: action.record,
        loading: false
      };
    case dmsConstants.DEAL_UPDATEID_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case dmsConstants.SAVE_RECORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dmsConstants.SAVE_RECORD_SUCCESS:
      return {
        ...state,
        currentRecord: action.record,
        loading: false
      };
    case dmsConstants.SAVE_RECORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case dmsConstants.LINEITEM_DELETE_REQUEST:
      return {
        ...state,
        loadingLineItems: true
      };
    case dmsConstants.LINEITEM_DELETE_SUCCESS:
      // let line_items_deleted = state.currentRecord.line_items.filter(
      //   i => i.id != action.line_item.id
      // );

      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          line_items: state.currentRecord.line_items.filter(
            i => i.id != action.line_item.id
          )
        },
        loadingLineItems: false
      };
    case dmsConstants.LINEITEM_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingLineItems: false
      };

    case dmsConstants.LINEITEM_ADD_REQUEST:
      return {
        ...state,
        loadingLineItems: true
      };
    case dmsConstants.LINEITEM_ADD_SUCCESS:
      let line_items_new = state.currentRecord.line_items;
      line_items_new.push(action.line_item);

      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          line_items: line_items_new
        },
        loadingLineItems: false
      };
    case dmsConstants.LINEITEM_ADD_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingLineItems: false
      };

    case dmsConstants.INVENTORY_GET_REQUEST:
      let obj1 = state.inventory[action.status];
      obj1.loading = true;
      // obj1.list = null;
      obj1.error = null;
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [action.status]: obj1,
          date: Date.now()
        }
      };
    case dmsConstants.INVENTORY_GET_SUCCESS:
      let obj2 = state.inventory[action.status];
      obj2.loading = false;
      obj2.list = action.inventory;
      obj2.error = null;
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [action.status]: obj2,
          date: Date.now()
        }
      };
    case dmsConstants.INVENTORY_GET_FAILURE:
      let obj3 = state.inventory[action.status];
      obj3.loading = false;
      obj3.list = null;
      obj3.error = action.error;
      return {
        ...state,
        inventory: {
          ...state.inventory,
          [action.status]: obj3,
          date: Date.now()
        }
      };

    case dmsConstants.GAPCOMPANIES_GET_REQUEST:
      return {
        ...state,
        gapCompanies: {
          ...state.gapCompanies,
          loading: true
        }
      };
    case dmsConstants.GAPCOMPANIES_GET_SUCCESS:
      return {
        ...state,
        gapCompanies: {
          ...state.gapCompanies,
          items: action.gapCompanies,
          loading: false
        }
      };
    case dmsConstants.GAPCOMPANIES_GET_FAILURE:
      return {
        ...state,
        gapCompanies: {
          ...state.gapCompanies,
          items: [],
          loading: false
        }
      };

    case dmsConstants.SERVICECOMPANIES_GET_REQUEST:
      return {
        ...state,
        serviceCompanies: {
          ...state.serviceCompanies,
          loading: true
        }
      };
    case dmsConstants.SERVICECOMPANIES_GET_SUCCESS:
      return {
        ...state,
        serviceCompanies: {
          ...state.serviceCompanies,
          items: action.serviceCompanies,
          loading: false
        }
      };
    case dmsConstants.SERVICECOMPANIES_GET_FAILURE:
      return {
        ...state,
        serviceCompanies: {
          ...state.serviceCompanies,
          items: [],
          loading: false
        }
      };

    case dmsConstants.DEALERS_GET_REQUEST:
      return {
        ...state,
        dealers: {
          ...state.dealers,
          loading: true
        }
      };
    case dmsConstants.DEALERS_GET_SUCCESS:
      return {
        ...state,
        dealers: {
          ...state.dealers,
          items: action.dealers,
          loading: false
        }
      };
    case dmsConstants.DEALERS_GET_FAILURE:
      return {
        ...state,
        dealers: {
          ...state.dealers,
          items: [],
          loading: false
        }
      };

    case dmsConstants.DOCTYPES_GET_REQUEST:
      return {
        ...state,
        documentTypes: {
          ...state.documentTypes,
          loading: true
        }
      };
    case dmsConstants.DOCTYPES_GET_SUCCESS:
      return {
        ...state,
        documentTypes: {
          ...state.documentTypes,
          items: action.documentTypes,
          loading: false
        }
      };
    case dmsConstants.DOCTYPES_GET_FAILURE:
      return {
        ...state,
        documentTypes: {
          ...state.documentTypes,
          items: [],
          loading: false
        }
      };

    case dmsConstants.SALESPERSONS_GET_REQUEST:
      return {
        ...state,
        salespersons: {
          ...state.salespersons,
          loading: true
        }
      };
    case dmsConstants.SALESPERSONS_GET_SUCCESS:
      return {
        ...state,
        salespersons: {
          ...state.salespersons,
          items: action.salespersons,
          loading: false
        }
      };
    case dmsConstants.SALESPERSONS_GET_FAILURE:
      return {
        ...state,
        salespersons: {
          ...state.salespersons,
          items: [],
          loading: false
        }
      };

    case dmsConstants.LIENHOLDERS_GET_REQUEST:
      return {
        ...state,
        lienholders: {
          ...state.lienholders,
          loading: true
        }
      };
    case dmsConstants.LIENHOLDERS_GET_SUCCESS:
      return {
        ...state,
        lienholders: {
          ...state.lienholders,
          items: action.lienholders.data,
          page: action.lienholders.current_page,
          per_page: action.lienholders.per_page,
          total: action.lienholders.total,
          loading: false
        }
      };
    case dmsConstants.LIENHOLDERS_GET_FAILURE:
      return {
        ...state,
        lienholders: {
          ...state.lienholders,
          loading: false
        }
      };

    case dmsConstants.LEADSOURCES_GET_REQUEST:
      return {
        ...state,
        leadSources: {
          ...state.leadSources,
          loading: true
        }
      };
    case dmsConstants.LEADSOURCES_GET_SUCCESS:
      return {
        ...state,
        leadSources: {
          ...state.leadSources,
          items: action.leadSources,
          loading: false
        }
      };
    case dmsConstants.LEADSOURCES_GET_FAILURE:
      return {
        ...state,
        leadSources: {
          ...state.leadSources,
          items: [],
          loading: false
        }
      };

    case dmsConstants.LEADS_GET_REQUEST:
      return {
        ...state,
        leads: {
          ...state.leads,
          [action.leadType]: {
            ...[action.leadType],
            loading: true
          }
        }
      };
    case dmsConstants.LEADS_GET_SUCCESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          [action.leadType]: {
            ...[action.leadType],
            items: action.leads.data,
            page: action.leads.current_page,
            per_page: action.leads.per_page,
            total: action.leads.total,
            loading: false
          }
        }
      };
    case dmsConstants.LEADS_GET_FAILURE:
      return {
        ...state,
        leads: {
          ...state.leads,
          [action.leadType]: {
            ...[action.leadType],
            items: [],
            loading: false
          }
        }
      };

    case dmsConstants.LEAD_SET_REQUEST:
      return {
        ...state
      };
    case dmsConstants.LEAD_SET_SUCCESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          open: {
            ...state.leads.open,
            items: state.leads.open.items.map(item =>
              item.id === action.lead.id ? action.lead : item
            )
          },
          closed: {
            ...state.leads.closed,
            items: state.leads.closed.items.map(item =>
              item.id === action.lead.id ? action.lead : item
            )
          },
          sold: {
            ...state.leads.sold,
            items: state.leads.sold.items.map(item =>
              item.id === action.lead.id ? action.lead : item
            )
          }
        }
      };
    case dmsConstants.LEAD_SET_FAILURE:
      return {
        ...state
      };

    case dmsConstants.CURRENTDEAL_SET:
      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          deal: {
            ...state.currentRecord.deal,
            [action.field]: action.value
          }
        }
      };

    case dmsConstants.CURRENTRECORD_SET:
      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          [action.field]: action.value
        }
      };

    case dmsConstants.CURRENTINVENTORY_SET:
      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          inventory: {
            ...state.currentRecord.inventory,
            [action.field]: action.value
          }
        }
      };

    case dmsConstants.CURRENTLINEITEM_SET:
      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          line_items: state.currentRecord.line_items.map(line_item =>
            line_item.id === action.id
              ? { ...line_item, [action.field]: action.value }
              : line_item
          )
        }
      };

    default:
      return state;
  }
}
