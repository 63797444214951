import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { dmsService } from "../../_services";

import { getUsStates } from "../../_helpers";

import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

// import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";

const defaultTradeObject = {
  vin: "",
  value: 0,
  allowance: 0,
  payoff: 0,
  internalValue: 0,
  mileage: 0,
  color: "",
  year: "",
  make: "",
  model: "",
  class: ""
};

export default function DealTrades(props) {
  const classes = useStyles();

  // state
  const [tabIndex, setTabIndex] = React.useState(0);
  const [trade1, setTrade1] = React.useState(
    Object.assign({}, defaultTradeObject)
  );
  const [trade2, setTrade2] = React.useState(
    Object.assign({}, defaultTradeObject)
  );
  const [trade3, setTrade3] = React.useState(
    Object.assign({}, defaultTradeObject)
  );

  React.useEffect(() => {
    if (props.record.trade1) {
      setTrade1(state => {
        return {
          vin: props.record.trade1.vin,
          value: props.record.trade1.buy_net,
          allowance: props.record.trade1_allowance,
          payoff: props.record.trade1.payoff_amount,
          internalValue: props.record.trade1.internal_value,
          mileage: props.record.trade1.odometer,
          color: props.record.trade1.color,
          year: props.record.trade1.year,
          make: props.record.trade1.make,
          model: props.record.trade1.model,
          class: props.record.trade1.class
        };
      });
    }
    if (props.record.trade2) {
      setTrade2(state => {
        return {
          vin: props.record.trade2.vin,
          value: props.record.trade2.buy_net,
          allowance: props.record.trade2_allowance,
          payoff: props.record.trade2.payoff_amount,
          internalValue: props.record.trade2.internal_value,
          mileage: props.record.trade2.odometer,
          color: props.record.trade2.color,
          year: props.record.trade2.year,
          make: props.record.trade2.make,
          model: props.record.trade2.model,
          class: props.record.trade2.class
        };
      });
    }
    if (props.record.trade3) {
      setTrade3(state => {
        return {
          vin: props.record.trade3.vin,
          value: props.record.trade3.buy_net,
          allowance: props.record.trade3_allowance,
          payoff: props.record.trade3.payoff_amount,
          internalValue: props.record.trade3.internal_value,
          mileage: props.record.trade3.odometer,
          color: props.record.trade3.color,
          year: props.record.trade3.year,
          make: props.record.trade3.make,
          model: props.record.trade3.model,
          class: props.record.trade3.class
        };
      });
    }
  }, [props.record.trade1, props.record.trade2, props.record.trade3]);

  // const {
  //   onChangeRecordProperty,
  //   onChangeDealProperty,
  //   onChangeInventoryProperty,
  //   dealerList,
  //   leadSourcesList
  // } = props;

  const { record } = props || {};
  //   const { date_inspection, date_sale, date_title } = record;

  const handleSetTrade = (index, values) => {
    console.log({ index, values });
    let errors = 0;
    if (values.vin == "") errors++;
    if (values.year == "" || values.year == 0) errors++;
    if (values.make == "") errors++;
    if (values.model == "") errors++;
    if (values.color == "") errors++;
    if (values.value == "" || values.value == 0) errors++;
    if (values.internalValue == "" || values.internalValue == 0) errors++;
    if (values.mileage == "" || values.mileage == 0) errors++;

    if (errors > 0) {
      alert(
        "vin, year, make, model, color, trade value, internal value, and mileage are required"
      );
      return;
    }

    dmsService.addTrade(props.record, index, values).then(() => {
      props.onRefreshRecord();
    });
  };

  const handleRemoveTrade = index => {
    console.log("handleRemoveTrade", index);
    dmsService.removeTrade(props.record, index).then(() => {
      props.onRefreshRecord();
    });
  };

  const vinLookup = (index, vin) => {
    console.log("vinLookup", { index, vin });
    let setTrade = null;
    if (index == 1) setTrade = setTrade1;
    if (index == 2) setTrade = setTrade2;
    if (index == 3) setTrade = setTrade3;

    setTrade(state => {
      return {
        ...state,
        vin: vin,
        year: defaultTradeObject.year,
        make: defaultTradeObject.make,
        model: defaultTradeObject.model,
        class: defaultTradeObject.class
      };
    });
    dmsService.vinDecode(vin).then(response => {
      if (
        response.Results &&
        response.Results.length == 1 &&
        response.Results[0].ErrorCode == 0
      ) {
        console.log("valid result");
        const values = response.Results[0];
        setTrade(state => {
          return {
            ...state,
            year: values.ModelYear,
            make: values.Make,
            model: values.Model,
            class: values.Series
          };
        });
      } else {
        console.log("invalid result");
      }
    });
  };

  if (!record) {
    return <Container className={classes.container}>Loading...</Container>;
  }

  return (
    <>
      <AppBar position="static">
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => {
            setTabIndex(newValue);
          }}
          variant="scrollable"
          aria-label="simple tabs example"
        >
          <Tab
            label={
              record.trade1
                ? `${record.trade1.year} ${record.trade1.make} ${record.trade1.model}`
                : "Trade 1"
            }
          />
          <Tab
            label={
              record.trade2
                ? `${record.trade2.year} ${record.trade2.make} ${record.trade2.model}`
                : "Trade 2"
            }
          />
          <Tab
            label={
              record.trade3
                ? `${record.trade3.year} ${record.trade3.make} ${record.trade3.model}`
                : "Trade 3"
            }
          />
        </Tabs>
      </AppBar>
      <Container className={classes.container}>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 0}
          id={`simple-tabpanel-0`}
          aria-labelledby={`simple-tab-0`}
        >
          <TradeTable
            tradeIndex={1}
            trade={trade1}
            isNew={record.trade1 ? false : true}
            recordTrade={record.trade1 || null}
            vinLookup={vinLookup}
            onSetTrade={handleSetTrade}
            onRemoveTrade={handleRemoveTrade}
          />
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 1}
          id={`simple-tabpanel-1`}
          aria-labelledby={`simple-tab-1`}
        >
          <TradeTable
            tradeIndex={2}
            trade={trade2}
            isNew={record.trade2 ? false : true}
            recordTrade={record.trade2 || null}
            vinLookup={vinLookup}
            onSetTrade={handleSetTrade}
            onRemoveTrade={handleRemoveTrade}
          />
        </Typography>
        <Typography
          component="div"
          role="tabpanel"
          hidden={tabIndex !== 2}
          id={`simple-tabpanel-2`}
          aria-labelledby={`simple-tab-2`}
        >
          <TradeTable
            tradeIndex={3}
            trade={trade3}
            isNew={record.trade3 ? false : true}
            recordTrade={record.trade3 || null}
            vinLookup={vinLookup}
            onSetTrade={handleSetTrade}
            onRemoveTrade={handleRemoveTrade}
          />
        </Typography>
      </Container>
    </>
  );
}

function TradeTable(props) {
  const [trade, setTrade] = React.useState(props.trade);
  const isNew = props.isNew;

  React.useEffect(() => {
    setTrade(props.trade);
  }, [props.trade]);

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>
            {isNew ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.onSetTrade(props.tradeIndex, trade)}
              >
                Add Trade
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => props.onRemoveTrade(props.tradeIndex)}
                  disabled={
                    props.recordTrade &&
                    props.recordTrade.open_sales_records_count > 0
                      ? true
                      : false
                  }
                >
                  Remove Trade
                </Button>
                {props.recordTrade &&
                  props.recordTrade.open_sales_records_count > 0 && (
                    <>(open sales records)</>
                  )}
              </>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            {isNew ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.vinLookup(props.tradeIndex, trade.vin)}
              >
                VIN Lookup
              </Button>
            ) : (
              <>VIN</>
            )}
          </TableCell>
          <TableCell>
            <Input
              value={trade.vin}
              style={{ width: "100%" }}
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, vin: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Trade Value</TableCell>
          <TableCell>
            <Input
              value={trade.value}
              style={{ width: "100%" }}
              type="number"
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, value: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Trade Allowance</TableCell>
          <TableCell>
            <Input
              value={trade.allowance}
              style={{ width: "100%" }}
              type="number"
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, allowance: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Trade Payoff</TableCell>
          <TableCell>
            <Input
              value={trade.payoff}
              style={{ width: "100%" }}
              type="number"
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, payoff: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Trade Internal Value</TableCell>
          <TableCell>
            <Input
              value={trade.internalValue}
              style={{ width: "100%" }}
              type="number"
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, internalValue: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Trade Mileage</TableCell>
          <TableCell>
            <Input
              value={trade.mileage}
              style={{ width: "100%" }}
              type="number"
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, mileage: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Color</TableCell>
          <TableCell>
            <Input
              value={trade.color}
              style={{ width: "100%" }}
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, color: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Year</TableCell>
          <TableCell>
            <Input
              value={trade.year}
              style={{ width: "100%" }}
              type="number"
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, year: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Make</TableCell>
          <TableCell>
            <Input
              value={trade.make}
              style={{ width: "100%" }}
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, make: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Model</TableCell>
          <TableCell>
            <Input
              value={trade.model}
              style={{ width: "100%" }}
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, model: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Class</TableCell>
          <TableCell>
            <Input
              value={trade.class}
              style={{ width: "100%" }}
              onChange={e => {
                const val = e.target.value;
                setTrade(state => {
                  return { ...state, class: val };
                });
              }}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  fixedHeight: {
    height: 240
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
