import { siteConstants } from '../_constants';

export function sites(state = {}, action) {
  switch (action.type) {
    case siteConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case siteConstants.GETALL_SUCCESS:
      return {
        items: action.sites
      };
    case siteConstants.GETALL_FAILURE:
      return { 
        error: action.error
      };
    case siteConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map(site =>
          site.id === action.site.id
            ? { ...site, updating: true }
            : site
        )
      };
    case siteConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map(site =>
          site.id === action.site.id
            ? {...site, updating: false, value: action.value }
            : site
        )
      };
    case siteConstants.UPDATE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    default:
      return state
  }
}