import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { PageWithAppDrawer } from "../_components/main/PageWithAppDrawer";
import InventoryMasterItem from "../_components/InventoryMasterItem";

// import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
// import Link from "@material-ui/core/Link";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { dmsActions } from "../_actions";

class InventoryMaster extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    console.log("constructor props", props);
    this.state = {
      print: this.props.print || false,
      filter: props.filter || ""
    };
  }
  componentDidMount() {
    // this.props.dispatch(configActions.getAll());
    // this.props.dispatch(siteActions.getAll());

    this.props.dispatch(dmsActions.getInventory("available"));
  }

  updateFilters = e => {
    this.setState({
      filter: e.target.value
    });
  };

  filterInventory() {
    const { filter } = this.state;
    if (null == this.props.dms.inventory.available.list) {
      return [];
    }
    if (filter === "") {
      return this.props.dms.inventory.available.list.data;
    }
    console.log("i count", this.props.dms.inventory.available);
    const filteredInventory = this.props.dms.inventory.available.list.data.filter(
      i => {
        if (i.vin && i.vin.search(filter) > -1) return true;
        if (i.stock_number && i.stock_number.search(filter) > -1) return true;
        // if (typeFilter != null && configType.search(typeFilter) === -1) match = false;
        // if (nameFilter != null && config.name.search(nameFilter) === -1) match = false;
        // if (siteFilter != null){
        //     console.log('siteFilter',siteFilter);
        //     console.log('config.site',config.site);
        //     if (siteFilter === 'global' && config.type !== 'global') match = false;
        //     if (siteFilter !== 'global' && config.type === 'global') match = false;
        //     if (siteFilter !== 'global' && config.site && config.site.code !== siteFilter) match = false;
        // }
        return false;
      }
    );
    return filteredInventory;
  }

  render() {
    const { classes } = this.props;
    const { print, filter } = this.state;

    const inventory = this.filterInventory();
    const loadingInventory = this.props.dms.inventory.available.loading;
    const error = this.props.dms.inventory.available.error;

    return (
      <PageWithAppDrawer pageTitle={"Inventory Master"}>
        {loadingInventory && <em>Loading Master Inventory...</em>}
        {error && <span className="text-danger">ERROR: {error}</span>}
        {inventory && (
          <Grid container item xs={12}>
            <Paper width="100%" className={classes.paper}>
              <Typography component="h1" variant="h4" align="center">
                Master Inventory{" "}
                {!print && (
                  <Button
                    variant="contained"
                    color="primary"
                    to={`/print/InventoryMaster?filter=${filter}`}
                    component={RouterLink}
                    target="_blank"
                  >
                    Print
                  </Button>
                )}
              </Typography>
              {!print && (
                <TextField
                  id="inventory-master-search"
                  label="Search"
                  // className={classes.textField}
                  value={filter}
                  onChange={this.updateFilters}
                  margin="normal"
                />
              )}
              <Grid container>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={2} md={2}>
                    Stock Number
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    VIN
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    Internal Price
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    Internet Price
                  </Grid>
                  {!print && (
                    <Grid item xs={12} sm={2} md={2}>
                      Lot
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box py={3}>
                      <Divider />
                    </Box>
                  </Grid>
                </Grid>
                {inventory.map(i => {
                  return (
                    <InventoryMasterItem key={i.id} item={i} print={print} />
                  );
                })}
              </Grid>
            </Paper>
          </Grid>
        )}
      </PageWithAppDrawer>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%"
  },
  fixedHeight: {
    height: 240
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

function mapStateToProps(state) {
  const { authentication, dms } = state;
  const { user } = authentication;
  return {
    user,
    dms
  };
}

const connectedInventoryMaster = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(InventoryMaster))
);
export { connectedInventoryMaster as InventoryMaster };
