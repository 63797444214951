import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";

import { getUsStates } from "../../_helpers";
import { ModalSalespersons } from "../ModalSalespersons";

import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

export default function DealBasicInfo(props) {
  const classes = useStyles();
  const usStates = getUsStates();

  // state
  const [salespersonsOpen, setSalespersonsOpen] = React.useState(false);

  const {
    onChangeRecordProperty,
    // onChangeDealProperty,
    // onChangeInventoryProperty,
    dealerList,
    salespersonsList
  } = props;

  const { record } = props || {};
  const { date_inspection, date_sale, date_title } = record;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ModalSalespersons
        open={salespersonsOpen}
        record={record}
        salespersonsList={salespersonsList || []}
        onChangeRecordProperty={onChangeRecordProperty}
        onClose={() => {
          setSalespersonsOpen(!salespersonsOpen);
        }}
      />
      <Container className={classes.container}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>VIN</TableCell>
              <TableCell>{record.inventory.vin}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sale Type</TableCell>
              <TableCell>
                <Select
                  name="sale_type"
                  value={record.sale_type}
                  onChange={onChangeRecordProperty}
                >
                  <MenuItem value="">--Sale Type--</MenuItem>
                  <MenuItem value={"retail"}>Retail</MenuItem>
                  <MenuItem value={"wholesale"}>Wholesale</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Deal Type</TableCell>
              <TableCell>
                <Select
                  name="type"
                  value={record.type}
                  onChange={onChangeRecordProperty}
                >
                  <MenuItem value="">--Deal Type--</MenuItem>
                  <MenuItem value={"cash"}>Cash Deal</MenuItem>
                  <MenuItem value={"finance"}>Finance Deal</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Vehicle Details</TableCell>
              <TableCell>
                {record.inventory.year} {record.inventory.make}{" "}
                {record.inventory.model} {record.inventory.class}{" "}
                {record.inventory.color}
              </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>Mileage History</TableCell>
              <TableCell>
                <i>TODO</i>
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell>Mileage Current</TableCell>
              <TableCell>
                <Input
                  value={record.inventory_mileage}
                  name="inventory_mileage"
                  onChange={onChangeRecordProperty}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Title State</TableCell>
              <TableCell>
                <Select
                  name="title_state"
                  value={record.title_state}
                  onChange={onChangeRecordProperty}
                >
                  <MenuItem value="">--Title State--</MenuItem>
                  {usStates.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Seller</TableCell>
              <TableCell>
                <Select
                  name="seller_id"
                  value={record.seller_id}
                  onChange={onChangeRecordProperty}
                >
                  <MenuItem value="">--Seller--</MenuItem>
                  {dealerList.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Salespeople</TableCell>
              <TableCell>
                {record.salesperson ? record.salesperson.name : null}
                {record.salesperson2 ? (
                  <>
                    <br />
                    {record.salesperson2.name}
                  </>
                ) : null}
                {record.salesperson3 ? (
                  <>
                    <br />
                    {record.salesperson3.name}
                  </>
                ) : null}

                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => setSalespersonsOpen(true)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sale Date</TableCell>
              <TableCell>
                <KeyboardDatePicker
                  name="date_sale"
                  margin="normal"
                  id="date-picker-dialog"
                  label="Sale Date"
                  format="MM/dd/yyyy"
                  value={`${date_sale} 12:00:00`}
                  onChange={(date, value) =>
                    onChangeRecordProperty(null, "date_sale", value)
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Title Date</TableCell>
              <TableCell>
                <KeyboardDatePicker
                  name="date_title"
                  margin="normal"
                  id="date-picker-dialog"
                  label="Title Date"
                  format="MM/dd/yyyy"
                  value={`${date_title} 12:00:00`}
                  onChange={(date, value) =>
                    onChangeRecordProperty(null, "date_title", value)
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Inspection Date</TableCell>
              <TableCell>
                <KeyboardDatePicker
                  name="date_inspection"
                  margin="normal"
                  id="date-picker-dialog"
                  label="Inspection Date"
                  format="MM/dd/yyyy"
                  value={`${date_inspection} 12:00:00`}
                  onChange={(date, value) =>
                    onChangeRecordProperty(null, "date_inspection", value)
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Container>
    </MuiPickersUtilsProvider>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  // paper: {
  //   padding: theme.spacing(2),
  //   display: "flex",
  //   overflow: "auto",
  //   flexDirection: "column",
  //   width: "100%"
  // },
  fixedHeight: {
    height: 240
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
