export const dmsConstants = {
  DEAL_GETID_REQUEST: "DMS_DEAL_GETID_REQUEST",
  DEAL_GETID_SUCCESS: "DMS_DEAL_GETID_SUCCESS",
  DEAL_GETID_FAILURE: "DMS_DEAL_GETID_FAILURE",

  DEAL_UPDATEID_REQUEST: "DMS_DEAL_UPDATEID_REQUEST",
  DEAL_UPDATEID_SUCCESS: "DMS_DEAL_UPDATEID_SUCCESS",
  DEAL_UPDATEID_FAILURE: "DMS_DEAL_UPDATEID_FAILURE",

  LINEITEM_DELETE_REQUEST: "LINEITEM_DELETE_REQUEST",
  LINEITEM_DELETE_SUCCESS: "LINEITEM_DELETE_SUCCESS",
  LINEITEM_DELETE_FAILURE: "LINEITEM_DELETE_FAILURE",

  LINEITEM_ADD_REQUEST: "LINEITEM_ADD_REQUEST",
  LINEITEM_ADD_SUCCESS: "LINEITEM_ADD_SUCCESS",
  LINEITEM_ADD_FAILURE: "LINEITEM_ADD_FAILURE",

  INVENTORY_GET_REQUEST: "INVENTORY_GET_REQUEST",
  INVENTORY_GET_SUCCESS: "INVENTORY_GET_SUCCESS",
  INVENTORY_GET_FAILURE: "INVENTORY_GET_FAILURE",

  GAPCOMPANIES_GET_REQUEST: "GAPCOMPANIES_GET_REQUEST",
  GAPCOMPANIES_GET_SUCCESS: "GAPCOMPANIES_GET_SUCCESS",
  GAPCOMPANIES_GET_FAILURE: "GAPCOMPANIES_GET_FAILURE",

  SERVICECOMPANIES_GET_REQUEST: "SERVICECOMPANIES_GET_REQUEST",
  SERVICECOMPANIES_GET_SUCCESS: "SERVICECOMPANIES_GET_SUCCESS",
  SERVICECOMPANIES_GET_FAILURE: "SERVICECOMPANIES_GET_FAILURE",

  DEALERS_GET_REQUEST: "DEALERS_GET_REQUEST",
  DEALERS_GET_SUCCESS: "DEALERS_GET_SUCCESS",
  DEALERS_GET_FAILURE: "DEALERS_GET_FAILURE",

  DOCTYPES_GET_REQUEST: "DOCTYPES_GET_REQUEST",
  DOCTYPES_GET_SUCCESS: "DOCTYPES_GET_SUCCESS",
  DOCTYPES_GET_FAILURE: "DOCTYPES_GET_FAILURE",

  SALESPERSONS_GET_REQUEST: "SALESPERSONS_GET_REQUEST",
  SALESPERSONS_GET_SUCCESS: "SALESPERSONS_GET_SUCCESS",
  SALESPERSONS_GET_FAILURE: "SALESPERSONS_GET_FAILURE",

  LEADSOURCES_GET_REQUEST: "LEADSOURCES_GET_REQUEST",
  LEADSOURCES_GET_SUCCESS: "LEADSOURCES_GET_SUCCESS",
  LEADSOURCES_GET_FAILURE: "LEADSOURCES_GET_FAILURE",

  LIENHOLDERS_GET_REQUEST: "LIENHOLDERS_GET_REQUEST",
  LIENHOLDERS_GET_SUCCESS: "LIENHOLDERS_GET_SUCCESS",
  LIENHOLDERS_GET_FAILURE: "LIENHOLDERS_GET_FAILURE",

  LEADS_GET_REQUEST: "LEADS_GET_REQUEST",
  LEADS_GET_SUCCESS: "LEADS_GET_SUCCESS",
  LEADS_GET_FAILURE: "LEADS_GET_FAILURE",

  LEAD_SET_REQUEST: "LEAD_SET_REQUEST",
  LEAD_SET_SUCCESS: "LEAD_SET_SUCCESS",
  LEAD_SET_FAILURE: "LEAD_SET_FAILURE",

  CURRENTDEAL_SET: "CURRENTDEAL_SET",
  CURRENTINVENTORY_SET: "CURRENTINVENTORY_SET",
  CURRENTRECORD_SET: "CURRENTRECORD_SET",
  CURRENTLINEITEM_SET: "CURRENTLINEITEM_SET",

  SAVE_RECORD_REQUEST: "DMS_SAVE_RECORD_REQUEST",
  SAVE_RECORD_SUCCESS: "DMS_SAVE_RECORD_SUCCESS",
  SAVE_RECORD_FAILURE: "DMS_SAVE_RECORD_FAILURE"
};
