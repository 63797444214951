import { authHeader } from "../_helpers";

export const userService = {
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ username, password })
  };

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  return fetch(`${url}/api/authentication`, requestOptions)
    .then(handleResponse)
    .then(response => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      console.log("response", response);
      let user = response.user;
      user.token = response.access_token;
      localStorage.setItem("user", JSON.stringify(user));

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  return fetch(`${url}/v3/admin/users`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  // const url = JSON.parse(localStorage.getItem('environment')).url;
  const url = "https://api2.autonerdusa.com";
  fetch(`${url}/v3/admin/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
  // const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(user)
  // };
  // const url = JSON.parse(localStorage.getItem('environment')).url;
  // return fetch(`https://api-dev.fastlaneams.com/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
  // const requestOptions = {
  //     method: 'PUT',
  //     headers: { ...authHeader(), 'Content-Type': 'application/json' },
  //     body: JSON.stringify(user)
  // };
  // return fetch(`https://api-dev.fastlaneams.com/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  // const requestOptions = {
  //     method: 'DELETE',
  //     headers: authHeader()
  // };
  // return fetch(`https://api-dev.fastlaneams.com/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        console.log("not authorized", 401);
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
        return Promise.reject("Not Authorized");
      }
      console.log("response not okay");

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    // console.log('response okay');

    return data.response ? data.response : data;
  });
}
