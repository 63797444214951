export function recordCashDealTotal(record) {
  if (!record) return 0;
  return recordAmountFinanced(record);
}

export function recordAmountFinanced(record) {
  // console.log("--------------------");
  // console.log("recordAmountFinance", record);
  if (!record) return 0;
  const total_tax = recordTotalTax(record);
  const fees_total = recordFeesTotal(record);
  const accessoriesTotal = recordAccessoriesTotal(record);

  // console.log("record.deal.price", record.deal.price);
  // console.log("fees_total", fees_total);
  // console.log(
  //   "record.deal.service_contract_price",
  //   record.deal.service_contract_price
  // );
  // console.log("record.deal.gap_price", record.deal.gap_price);
  // console.log("total_tax", total_tax);
  // console.log("accessoriesTotal", accessoriesTotal);
  // console.log("record.deal.down_payment_cash", record.deal.down_payment_cash);

  return (
    Number(record.deal.price) +
    Number(fees_total) +
    Number(record.deal.service_contract_price) +
    Number(record.deal.gap_price) +
    Number(total_tax) +
    Number(accessoriesTotal) -
    Number(record.deal.down_payment_cash)
  );
}

export function recordTotalTax(record) {
  if (!record) return 0;
  return Number((recordTaxableAmount(record) * record.deal.tax_rate) / 100);
}

export function recordTaxableAmount(record) {
  if (!record) return 0;
  return Number(record.deal.price) + Number(record.deal.fees_doc);
}

export function recordAccessoriesTotal(record) {
  if (!record) return 0;
  let accessoriesTotal = 0;
  // console.log("line_items", record.line_items);
  record.line_items.forEach(function(line_item) {
    accessoriesTotal += parseFloat(line_item.amount);
  });
  return accessoriesTotal;
}

export function recordFeesTotal(record) {
  if (!record) return 0;
  return (
    Number(record.deal.fees_license) +
    Number(record.deal.fees_title) +
    Number(record.deal.fees_filing) +
    Number(record.deal.fees_other) +
    Number(record.deal.fees_doc) +
    Number(record.deal.fees_lender_processing)
  );
}

export function recordFrontGross(record) {
  if (!record) return 0;
  return (
    Number(record.deal.price) +
    Number(record.deal.fees_doc) -
    Number(record.inventory.expenses_total) -
    Number(record.inventory.buy_net)
  );
}

export function recordTradesAllowanceTotal(record) {
  if (!record) return 0;
  let total = 0;
  if (record.trade1) total += Number(record.trade1_allowance);
  if (record.trade2) total += Number(record.trade2_allowance);
  if (record.trade3) total += Number(record.trade3_allowance);
  return total;
}

export function recordTradesPayoffTotal(record) {
  if (!record) return 0;
  let total = 0;
  if (record.trade1) total += Number(record.trade1.payoff_amount);
  if (record.trade2) total += Number(record.trade2.payoff_amount);
  if (record.trade3) total += Number(record.trade3.payoff_amount);
  return total;
}

export function recordMonthlyPayment(record) {
  if (!record) return 0;

  const price = Number(record.deal.price);
  const months = Number(record.deal.finance_term);
  const effectiveApr =
    Number(record.deal.finance_contract_apr) +
    Number(record.deal.finance_buy_rate);

  const monthlyPayment = calculateMonthlyPayment(months, effectiveApr, price);
  return monthlyPayment;
}

export function recordBuyRateInterest(record) {
  const amountFinanced = Number(recordAmountFinanced(record));
  const months = Number(record.deal.finance_term);
  const apr = Number(record.deal.finance_buy_rate);

  return calculateTotalInterest(months, apr, amountFinanced);
}
export function recordParticipationRateInterest(record) {
  const amountFinanced = Number(recordAmountFinanced(record));
  const months = Number(record.deal.finance_term);
  const apr = Number(record.deal.finance_participation_rate);

  return calculateTotalInterest(months, apr, amountFinanced);
}

export function recordFinanceReserveValue(record) {
  if (!record) return 0;

  return 0;
}

export function calculateMonthlyPayment(months, apr, price) {
  months = Number(months);
  const effectiveApr = apr;
  const monthlyApr = Number(effectiveApr) / 100 / 12;
  const numerator = monthlyApr * Math.pow(1 + monthlyApr, months);
  const denominator = Math.pow(1 + monthlyApr, months) - 1;
  const monthlyPayment = ((Number(price) * numerator) / denominator)
    .toFixed(2)
    .toString();

  return monthlyPayment;
}

export function calculateTotalInterest(months, apr, price) {
  const totalInterest = (apr / months) * price;
  return totalInterest;
}
