import React, { PureComponent } from "react";
// import ReactModal from "react-modal";
import { connect } from "react-redux";

import InventoryMasterDetails from "./InventoryMasterDetails";

// import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { Collapse } from "@material-ui/core";

export default class InventoryMasterItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      print: this.props.print || false,
      expanded: false
    };
  }

  render() {
    const { expanded } = this.state;
    const { item: i } = this.props;

    return (
      <Grid
        key={i.id}
        container
        alignItems="center"
        onClick={this.toggleMoreInfo}
      >
        <Grid item xs={12} sm={2} md={2}>
          {i.stock_number}
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          {i.vin}
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          ${i.buy_net}
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          ${i.internet_price}
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          {i.physical_location}
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid item xs={12} sm={12} md={12}>
            <InventoryMasterDetails item={i} />
          </Grid>
        </Collapse>
        <Grid item xs={12}>
          <Box py={3}>
            <Divider />
          </Box>
        </Grid>
      </Grid>
    );
  }

  toggleMoreInfo = () => {
    this.setState(prevState => {
      return { expanded: !prevState.expanded };
    });
  };
}

function mapStateToProps(state) {
  const { dms } = state;
  return {
    dms
  };
}

const connectedInventoryMasterItem = connect(mapStateToProps)(
  InventoryMasterItem
);
export { connectedInventoryMasterItem as InventoryMasterItem };
