import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
// import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import InputLabel from "@material-ui/core/InputLabel";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const dudUrl = "javascript:;";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

let headCells = [
  { id: "customer", numeric: true, disablePadding: false, label: "Customer" },
  { id: "subject", numeric: true, disablePadding: false, label: "Subject" },
  { id: "vin", numeric: true, disablePadding: false, label: "VIN" },
  {
    id: "received",
    numeric: true,
    disablePadding: false,
    label: "Last Updated"
  },
  { id: "unread", numeric: true, disablePadding: false, label: "Unread" },
  { id: "user", numeric: true, disablePadding: false, label: "User" }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Leads
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function MyLeadsTable2(props) {
  const classes = useStyles();
  const [totalRows, setTotalRows] = React.useState(props.total);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(props.page);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.per_page);

  const [details, setDetails] = React.useState();
  const [modalOpen, setModalOpen] = React.useState(false);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = props.rows.map(n => n.vehicleid);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    console.log("handleChangePage", newPage);
    setPage(newPage);
    props.onChangeData(props.type, newPage, rowsPerPage);
  }

  function handleChangeRowsPerPage(event) {
    console.log("handleChangeRowsPerPage", +event.target.value);
    setRowsPerPage(+event.target.value);
    setPage(0);
    props.onChangeData(props.type, 0, +event.target.value);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  const isSelected = id => selected.indexOf(id) !== -1;

  const openModal = lead => {
    setDetails({ lead });
    setModalOpen(true);
    if (lead.is_read === false) {
      props.onUpdateLead(props.type, lead, {
        is_read: true
      });
    }
  };

  return (
    <>
      <div className={classes.root}>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
        <Paper className={classes.paper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page"
            }}
            nextIconButtonProps={{
              "aria-label": "next page"
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <EnhancedTableToolbar numSelected={selected.length} />
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.rows.length}
              />
              <TableBody>
                {props.rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Select
                          name="status"
                          value={row.status}
                          onChange={e => {
                            props.onUpdateLead(props.type, row, {
                              status: e.target.value
                            });
                          }}
                        >
                          <MenuItem value={"open"}>Open</MenuItem>
                          <MenuItem value={"closed"}>Closed</MenuItem>
                          <MenuItem value={"sold"}>Sold</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        {row.email === null || row.email === "" ? (
                          <i>Unknown Customer</i>
                        ) : (
                          row.email
                        )}
                        <br />
                        <Select
                          name="status2"
                          value={row.status2 == null ? "" : row.status2}
                          displayEmpty
                          onChange={e => {
                            props.onUpdateLead(props.type, row, {
                              status2: e.target.value
                            });
                          }}
                        >
                          <MenuItem value={""}>Prospect Status</MenuItem>
                          <MenuItem value={"open"}>Hot</MenuItem>
                          <MenuItem value={"closed"}>2 weeks out</MenuItem>
                          <MenuItem value={"sold"}>30 days out</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell
                        align="right"
                        onClick={() => openModal(row)}
                        style={{
                          fontWeight: row.is_read == 0 ? "bold" : "normal"
                        }}
                      >
                        {row.subject}
                      </TableCell>
                      <TableCell align="right">{row.vin}</TableCell>
                      <TableCell align="right">{row.updated_at}</TableCell>
                      <TableCell align="right">
                        {row.is_read == true && (
                          <Link
                            href={dudUrl}
                            onClick={() =>
                              props.onUpdateLead(props.type, row, {
                                is_read: false
                              })
                            }
                          >
                            Mark as Unread
                          </Link>
                        )}
                        {row.is_read == false && (
                          <Link
                            href={dudUrl}
                            onClick={() =>
                              props.onUpdateLead(props.type, row, {
                                is_read: true
                              })
                            }
                          >
                            <b>Mark as Read</b>
                          </Link>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {row.salesperson.email}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page"
            }}
            nextIconButtonProps={{
              "aria-label": "next page"
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <Dialog
        open={modalOpen}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={true}
      >
        <DialogTitle id="form-dialog-title">Lead Details</DialogTitle>
        <DialogActions>
          <Button onClick={() => setModalOpen(!modalOpen)} color="primary">
            Close
          </Button>
        </DialogActions>
        <DialogContent>
          <DialogContentText>
            {details ? details.lead.subject : "nothing"}
            <br />
            {details ? (
              <div
                dangerouslySetInnerHTML={{ __html: details.lead.content }}
              ></div>
            ) : (
              "loading..."
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(!modalOpen)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
