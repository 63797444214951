import React from "react";
import { connect } from "react-redux";
// import { Link as RouterLink } from "react-router-dom";

import { PageWithAppDrawer } from "../_components/main/PageWithAppDrawer";
import InventoryMasterItem from "../_components/InventoryMasterItem";

// import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
// import Link from "@material-ui/core/Link";

// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

import { dmsActions } from "../_actions";

class InventoryOpen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      print: this.props.print || false,
      filter: props.filter || "",
      defaultFilters: [
        {
          field: "status",
          operator: "eq",
          value: "1"
        }
      ],
      page: this.props.dms.inventory.open.current_page || 1,
      per_page: this.props.dms.inventory.open.per_page || 5,
      total: this.props.dms.inventory.open.total || 0
    };
  }
  componentDidMount() {
    this.handleChangePage(null, 1);
  }

  updateFilters = e => {
    this.setState({
      filter: e.target.value
    });
  };

  filterInventory() {
    const { filter } = this.state;
    if (null == this.props.dms.inventory.open.list) {
      return [];
    }
    if (filter === "") {
      return this.props.dms.inventory.open.list.data;
    }
    const filteredInventory = this.props.dms.inventory.open.list.data.filter(
      i => {
        if (i.vin && i.vin.search(filter) > -1) return true;
        if (i.stock_number && i.stock_number.search(filter) > -1) return true;
        // if (typeFilter != null && configType.search(typeFilter) === -1) match = false;
        // if (nameFilter != null && config.name.search(nameFilter) === -1) match = false;
        // if (siteFilter != null){
        //     console.log('siteFilter',siteFilter);
        //     console.log('config.site',config.site);
        //     if (siteFilter === 'global' && config.type !== 'global') match = false;
        //     if (siteFilter !== 'global' && config.type === 'global') match = false;
        //     if (siteFilter !== 'global' && config.site && config.site.code !== siteFilter) match = false;
        // }
        return false;
      }
    );
    return filteredInventory;
  }

  getNewData = (page = null, per_page = null) => {
    if (!per_page) per_page = this.state.per_page;
    if (!page) page = this.state.page;
    this.props.dispatch(
      dmsActions.getInventory(
        "open",
        this.state.defaultFilters,
        null, // relations
        this.state.per_page,
        this.state.page
      )
    );
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage + 1
      },
      () => this.getNewData()
    );
  };

  handleChangeRowsPerPage = event => {
    const value = parseInt(event.target.value, 10);
    this.setState(
      {
        per_page: value,
        page: 1
      },
      () => this.getNewData()
    );
  };

  render() {
    const { classes } = this.props;
    const { print, filter } = this.state;

    const inventory = this.props.dms.inventory.open;
    const { loadingInventory, error, list } = inventory;
    const { total, data } = list || {};
    const { page, per_page } = this.state;

    return (
      <PageWithAppDrawer pageTitle={"Dashboard"}>
        {error && <span className="text-danger">ERROR: {error}</span>}
        {data && !loadingInventory && (
          <Grid container item xs={12}>
            <Paper width="100%" className={classes.paper}>
              <Typography component="h1" variant="h4" align="center">
                Open Inventory{" "}
                {/* {!print && (
                  <Button
                    variant="contained"
                    color="primary"
                    to={`/print/InventoryMaster?filter=${filter}`}
                    component={RouterLink}
                    target="_blank"
                  >
                    Print
                  </Button>
                )} */}
              </Typography>
              {/* {!print && (
                <TextField
                  id="inventory-open-search"
                  label="Search"
                  value={filter}
                  onChange={this.updateFilters}
                  margin="normal"
                />
              )} */}
              <Table>
                <TableBody>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={3}
                      count={parseInt(total)}
                      rowsPerPage={parseInt(per_page)}
                      page={parseInt(page) - 1}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableBody>
              </Table>
              <Grid container>
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={2} md={2}>
                    Stock Number
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    VIN
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    Internal Price
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}>
                    Internet Price
                  </Grid>
                  {!print && (
                    <Grid item xs={12} sm={2} md={2}>
                      Lot
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box py={3}>
                      <Divider />
                    </Box>
                  </Grid>
                </Grid>
                {data.map(i => {
                  return (
                    <InventoryMasterItem key={i.id} item={i} print={print} />
                  );
                })}
              </Grid>
            </Paper>
          </Grid>
        )}
      </PageWithAppDrawer>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    width: "100%"
  },
  fixedHeight: {
    height: 240
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function mapStateToProps(state) {
  const { authentication, dms } = state;
  const { user } = authentication;
  return {
    user,
    dms
  };
}

const connectedInventoryOpen = connect(mapStateToProps)(
  withWidth()(withStyles(styles)(InventoryOpen))
);
export { connectedInventoryOpen as InventoryOpen };
