import React, { PureComponent } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { InventoryMaster } from "../Pages/InventoryMaster";

class PrintPage extends PureComponent {
  render() {
    const { page } = this.props.match.params;

    if (page === "InventoryMaster") {
      const { filter } = queryString.parse(this.props.location.search);
      return <InventoryMaster print={true} filter={filter} />;
    }
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user
  };
}

const connectedPrintPage = connect(mapStateToProps)(PrintPage);
export { connectedPrintPage as PrintPage };
